<ng-template #modal>
    <div class="modal-header">
        <h5 class="modal-title">{{ modalConfig.modalTitle | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="dismiss()"
            *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()"
            [disabled]="modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-content></ng-content>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="dismiss()"
            *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()"
            [disabled]="modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()">
            {{ modalConfig.dismissButtonLabel | translate }}
        </button>
        <button type="button" class="btn btn-{{ modalConfig.customAction1ButtonType }}" (click)="customAction1()"
            *ngIf="modalConfig.hideCustomAction1Button === undefined || !modalConfig.hideCustomAction1Button()"
            [disabled]="modalConfig.disableCustomAction1Button !== undefined && modalConfig.disableCustomAction1Button()">
            {{ modalConfig.customAction1ButtonLabel | translate }}
        </button>
        <button type="button" class="btn btn-{{ modalConfig.customAction2ButtonType }}" (click)="customAction2()"
            *ngIf="modalConfig.hideCustomAction2Button === undefined || !modalConfig.hideCustomAction2Button()"
            [disabled]="modalConfig.disableCustomAction2Button !== undefined && modalConfig.disableCustomAction2Button()">
            {{ modalConfig.customAction2ButtonLabel | translate }}
        </button>
        <button type="button" class="btn btn-{{ modalConfig.customAction3ButtonType }}" (click)="customAction3()"
            *ngIf="modalConfig.hideCustomAction3Button === undefined || !modalConfig.hideCustomAction3Button()"
            [disabled]="modalConfig.disableCustomAction3Button !== undefined && modalConfig.disableCustomAction3Button()">
            {{ modalConfig.customAction3ButtonLabel | translate }}
        </button>
        <button type="button" class="btn btn-primary" (click)="close()"
            *ngIf="modalConfig.hideCloseButton === undefined || !modalConfig.hideCloseButton()"
            [disabled]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()">
            {{ modalConfig.closeButtonLabel | translate }}
        </button>
    </div>
</ng-template>