import {
  Component,
  Injectable,
  Input,
  Output,
  OnInit,
  OnDestroy,
  EventEmitter,
  ViewChild,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { TableColumn } from "@swimlane/ngx-datatable";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject } from "rxjs/Rx";
import { CustomAction } from "./action.model";
import { GridModel } from "./grid.model";
import { RowAction } from "./row-action.model";

/**
 *
 * Example:
 *
 * data-grid.component.html
 *
 *    <app-datatable
 *        (onFetchDataRequired)="fetchDataRequired($event)"
 *        [gridModelInput]="gridModel">
 *    </app-datatable>
 *
 *
 * data-grid.component.ts
 *
 *    import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
 *
 *    import { DatatableComponent } from "app/shared/components/datatable/datatable.component";
 *    import { GridModel } from "app/shared/components/datatable/grid.model";
 *    import { ExampleModel } from "app/shared/models/example.model";
 *    import { DataFetchService } from "app/shared/api/example.service";
 *
 *    @Component({
 *      templateUrl: 'data-grid.component.html'
 *    })
 *    export class DataDatatableComponent implements OnInit {
 *      @ViewChild(DatatableComponent) grid: DatatableComponent<ExampleModel>;
 *      gridModel: GridModel<ExampleModel> = new GridModel<ExampleModel>('DateCreated', 'desc');
 *
 *      ngOnInit(): void {
 *        this.grid.columns = [
 *          { prop: 'Id', cellTemplate: this.grid.idAnchorEditTemplate, headerTemplate: this.grid.emptyTemplate }
 *          , { prop: 'CreatedBy' }
 *          , { prop: 'money', cellTemplate: this.grid.currencyTemplate, name: 'Money' }
 *          , { prop: 'method', cellTemplate: this.grid.translateTemplate, name: 'Method' }
 *          , { prop: 'date', cellTemplate: this.grid.dateTemplate, name: 'Date' }
 *          , { prop: 'created_at', cellTemplate: this.grid.dateTimeTemplate, name: 'Created At' }
 *        ];
 *      }
 *
 *      constructor(private dataFetchService: DataFetchService) {
 *      }
 *
 *      fetchDataRequired(gridModel: GridModel<ExampleModel>) {
 *        this.dataFetchService
 *          .getSortedPagedResults(gridModel)
 *          .subscribe(gridModelResponse => {
 *            this.gridModel = gridModelResponse;
 *        });
 *      }
 *    }
 */

@Injectable()
@Component({
  selector: "app-datatable",
  templateUrl: "./datatable.component.html",
  styleUrls: [
    "./datatable.component.scss",
    "../../../../assets/sass/libs/select.scss",
    "../../../../assets/sass/libs/datatables.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DatatableComponent<T> implements OnInit, OnDestroy {
  @Input() customActions: CustomAction[];

  @Input() rowActions: RowAction[];

  @Input() optionColumns = [];

  columns: TableColumn[] = [];

  isEditable = {};

  allRemovableColumns = [];
  selectedColumns: TableColumn[];
  selectAllColumns = false;

  selectedRows = [];

  private _gridModelInput = new BehaviorSubject<GridModel<T>>(undefined);

  @ViewChild("translateColumnTemplate", { static: true })
  public translateColumnTemplate: TemplateRef<any>;

  @ViewChild("checkboxColumnTemplate")
  public checkboxColumnTemplate: TemplateRef<any>;

  @ViewChild("checkboxCellTemplate")
  public checkboxCellTemplate: TemplateRef<any>;

  @ViewChild("emptyTemplate", { static: true })
  public emptyTemplate: TemplateRef<any>;

  @ViewChild("valueTemplate", { static: true })
  public valueTemplate: TemplateRef<any>;

  @ViewChild("translateTemplate", { static: true })
  public translateTemplate: TemplateRef<any>;

  @ViewChild("currencyTemplate", { static: true })
  public currencyTemplate: TemplateRef<any>;

  @ViewChild("dateTemplate", { static: true })
  public dateTemplate: TemplateRef<any>;

  @ViewChild("dateTimeTemplate", { static: true })
  public dateTimeTemplate: TemplateRef<any>;

  @ViewChild("booleanTemplate", { static: true })
  public booleanTemplate: TemplateRef<any>;

  @ViewChild("downloadTemplate", { static: true })
  public downloadTemplate: TemplateRef<any>;

  @ViewChild("chipsTemplate", { static: true })
  public chipsTemplate: TemplateRef<any>;

  @ViewChild("avatarTemplate", { static: true })
  public avatarTemplate: TemplateRef<any>;

  @ViewChild("selectOptionsTemplate", { static: true })
  public selectOptionsTemplate: TemplateRef<any>;

  @ViewChild("actionsTemplate", { static: true })
  public actionsTemplate: TemplateRef<any>;

  @ViewChild("inlineEditingActionsTemplate", { static: true })
  public inlineEditingActionsTemplate: TemplateRef<any>;

  // change data to use getter and setter
  @Input()
  set gridModelInput(value) {
    // set the latest value for _data BehaviorSubject
    if (value !== undefined) {
      this._gridModelInput.next(value);
    }
  }

  get gridModelInput() {
    // get the latest value from _data BehaviorSubject
    return this._gridModelInput.getValue();
  }

  @Output()
  onFetchDataRequired = new EventEmitter<GridModel<T>>();

  gridModel: GridModel<T>;
  isLoading: boolean = false;
  currentPageLimit: number = 0;
  pageLimitOptions = [
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];

  @Output()
  onActionDeleteRequired = new EventEmitter<GridModel<T>>();
  selectedAction: string = "";

  constructor(
    private translateService: TranslateService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.optionColumns.map((col) => {
      if (col.colRemovable) this.allRemovableColumns.push(col);
    });
    this.columns = this.optionColumns;
    this.selectedColumns = this.allRemovableColumns;
    this.selectAllColumns = true;
    this.gridModel = new GridModel<T>();
    this._gridModelInput.subscribe(
      (gridModel) => {
        this.gridModel = gridModel;
        this.isLoading = false;
        this.spinner.hide();
      },
      (err) => console.log(err)
    );

    this.loadPage();
  }

  ngOnDestroy(): void {
    this._gridModelInput.unsubscribe();
  }

  /**
   *
   * Sorting Functions
   *
   */

  protected loadPage(pageEvent = { offset: 0 }) {
    this.gridModel.CurrentPageNumber = pageEvent.offset;
    this.isLoading = true;
    this.spinner.show();
    this.onFetchDataRequired.emit(this.gridModel);
  }

  protected onSort(event) {
    if (this.gridModel.SortBy != event.sorts[0].prop) {
      //this means we are sorting on a new column
      //so we need to return the paging to the first page
      this.gridModel.CurrentPageNumber = 0;
    }

    this.gridModel.SortBy = event.sorts[0].prop;
    this.gridModel.SortDir = event.sorts[0].dir === "desc" ? "-" : "";

    this.loadPage();
  }

  public onLimitChange(limit: any): void {
    this.gridModel.PageSize = this.currentPageLimit = parseInt(limit, 10);
    this.gridModel.CurrentPageNumber = 0;
    this.loadPage();
  }

  /**
   *
   * Column Selection Functions
   *
   */

  onSelectColumnTouched() {
    if (this.columns.length > 0) {
      this.columns = [];
      this.columns.push(this.optionColumns[0]);
      this.selectedColumns.map((col) => {
        this.columns.push(col);
      });
      this.columns.push(this.optionColumns.slice(-1)[0]);
    }
  }

  compareSelectColumnsFn(item, selected) {
    return item === selected;
  }

  protected onSelectColumnChange(selectedItems) {
    if (Array.isArray(selectedItems)) {
      const newList = selectedItems.map((x) => x);
      this.selectedColumns = [...newList];
      this.selectAllColumns =
        this.allRemovableColumns.length === this.selectedColumns.length;
      this.onSelectColumnTouched();
    }
  }

  protected columnIsChecked(col) {
    return (
      this.columns.find((c) => {
        return c && c.name === col.name;
      }) !== undefined
    );
  }

  protected toggleCheckAll(event, selected) {
    if (event.currentTarget.checked) {
      this.selectAllItems();
    } else {
      this.unselectAllItems();
    }
  }

  private selectAllItems() {
    const newList = this.allRemovableColumns.map((x) => x);
    this.selectedColumns = [...newList];
    this.columns = this.optionColumns;
  }

  private unselectAllItems() {
    this.selectedColumns = [];
    this.columns = [];
  }

  /**
   *
   * Row Action functions
   *
   */

  public onSelectActionChange(action: any) {
    this.selectedAction = action;
  }

  onActionSelected() {
    switch (this.selectedAction) {
      case "1":
        console.log("delete selected", this.selectedRows);
        break;

      default:
        break;
    }
  }

  onActivate(event) {
    // console.log("Activate Event", event);
  }

  onSelect({ selected }) {
    // console.log("Select Event", selected, this.selectedRows);
    this.selectedRows.splice(0, this.selectedRows.length);
    this.selectedRows.push(...selected);
    this.selectedAction = "";
  }

  save(row, value) {
    console.log(row, value);
    this.isEditable[value] = !this.isEditable[value];
  }

  delete(row, value) {
    console.log(row, value);
    this.isEditable[value] = !this.isEditable[value];
  }

  findOptionById(options, value) {
    // console.log("findOptionById", options, value);
    return options.find((o) => o.value === value) || null;
  }
}
