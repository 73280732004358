import { AbstractControl, FormControl, ValidationErrors } from "@angular/forms";

// export interface MyAbstractControl extends AbstractControl {
//   warnings: any;
// }

export class MyFormControl extends FormControl {
  warnings: any;

  getWarning(warningCode: string, path?: Array<string | number> | string): any {
    const control = (path ? this.get(path) : this) as MyFormControl;
    return control && control.warnings ? control.warnings[warningCode] : null;
  }

  setWarnings(
    warns: ValidationErrors | null,
    opts: { emitEvent?: boolean } = {}
  ): void {
    this.warnings = warns;
  }

  hasWarning(
    warningCode: string,
    path?: Array<string | number> | string
  ): boolean {
    return !!this.getWarning(warningCode, path);
  }

  deleteWarning(
    warningCode: string,
    path?: Array<string | number> | string
  ): void {
    const control = (path ? this.get(path) : this) as MyFormControl;
    if (this.hasWarning(warningCode)) delete this.warnings[warningCode];

    if (this.warnings && !Object.keys(this.warnings).length) {
      control.setWarnings(null);
    }
  }

  deleteError(errorCode: string, path?: Array<string | number> | string): void {
    const control = (path ? this.get(path) : this) as MyFormControl;
    if (this.hasError(errorCode)) delete this.errors[errorCode];

    if (this.errors && !Object.keys(this.errors).length) {
      control.setErrors(null);
    }
  }
}
