import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Shift } from '../models/shift';
import { SessionStorageService } from '../services/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ShiftService extends AbstractService {

  private url = '/shifts';

  constructor(httpClient: HttpClient, encryptStorage: SessionStorageService){
    super(httpClient, encryptStorage)
  }

  find(params: any): Observable<Shift[]> {
    return this.get(`${this.url}/`, params);
  }

  retrieve(id: any): Observable<Shift> {
    return this.get(`${this.url}/${id}/`);
  }

  create(data: any): Observable<any> {
    return this.post(`${this.url}/`, data);
  }

  update(id: any, data: any): Observable<any> {
    return this.put(`${this.url}/${id}/`, data);
  }

  remove(id: any): Observable<any> {
    return this.delete(`${this.url}/${id}/`);
  }

  /*
  findByTitle(title: any): Observable<Shift[]> {
    return this.get(`${this.url}/?title=${title}`);
  }
*/
}