import { Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "formly-field-modal-input",
  template: `
    <div class="form-group">
      <label [attr.for]="id" *ngIf="to.label">
        {{ to.label }}
        <span *ngIf="to.required">*</span>
      </label>
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <i class="fa-solid fa-key" aria-hidden="true"></i>
          </div>
        </div>
        <input
          [type]="type"
          [formControl]="formControl"
          [formlyAttributes]="field"
          class="form-control"
          [class.is-invalid]="showError"
          placeholder="{{ to.placeholder }}"
          autocomplete="off"
        />
        <div
          *ngIf="to.modable"
          class="input-group-addon btn btn btn-primary submit-button ml-1"
          (click)="onClick($event)"
        >
          <i class="fa-solid fa-link"></i>
        </div>
      </div>
      <div *ngIf="showError" class="col-sm-3 invalid-feedback d-block">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>
    </div>
  `,
})
export class FormlyFieldModalInputModal extends FieldType {
  defaultOptions = {
    templateOptions: {
      modable: false,
    },
  };

  get type() {
    return this.to.type || "text";
  }

  onClick($event) {
    if (this.to.onClick) {
      this.to.onClick($event);
    }
  }
}
