import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ChartData, ChartType, ChartEvent, ChartConfiguration } from "chart.js";
import { BaseChartDirective } from "ng2-charts";

@Component({
  selector: "app-dashboards-polar-area-chart",
  templateUrl: "./polar-area-chart.component.html",
  styleUrls: ["./polar-area-chart.component.scss"],
})
export class PolarAreaChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  @Input() labels: any[] = [];
  @Input() dataset: any[] = [];
  
  public chartOptions: ChartConfiguration["options"] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      }
    },
  };
  public chartData: ChartData<"polarArea"> = {
    labels: this.labels,
    datasets: this.dataset,
  };
  public legend = true;

  public chartType: ChartType = "polarArea";

  constructor() {}

  ngOnInit(): void {
    this.chartData = {
      labels: this.labels,
      datasets: this.dataset,
    };
    //this.chart.update();
  }

  // events
  public chartClicked({
    event,
    active,
  }: {
    event: ChartEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event: ChartEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }
}
