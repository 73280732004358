import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";
import { SessionStorageService } from "../services/session-storage.service";

@Injectable()
export abstract class AbstractService {
  private baseUrl = `${environment.apiUrl}`;

  constructor(
    public httpClient: HttpClient,
    public encryptStorage: SessionStorageService
  ) {}

  get<T>(
    url: string,
    config: object = {},
    addCreds: boolean = true
  ): Observable<T> {
    return this.httpClient.get<T>(
      `${this.baseUrl}${url}`,
      this.mergeConfig(config, addCreds)
    );
  }

  post<T>(
    url: string,
    data: any,
    config: object = {},
    addCreds: boolean = true
  ): Observable<T> {
    return this.httpClient.post<T>(
      `${this.baseUrl}${url}`,
      data,
      this.mergeConfig(config, addCreds)
    );
  }

  patch<T>(
    url: string,
    data: any,
    config: object = {},
    addCreds: boolean = true
  ): Observable<T> {
    return this.httpClient.patch<T>(
      `${this.baseUrl}${url}`,
      data,
      this.mergeConfig(config, addCreds)
    );
  }

  put<T>(
    url: string,
    data: any,
    config: object = {},
    addCreds: boolean = true
  ): Observable<T> {
    return this.httpClient.put<T>(
      `${this.baseUrl}${url}`,
      data,
      this.mergeConfig(config, addCreds)
    );
  }

  delete<T>(
    url: string,
    config: object = {},
    addCreds: boolean = true
  ): Observable<T> {
    return this.httpClient.delete<T>(
      `${this.baseUrl}${url}`,
      this.mergeConfig(config, addCreds)
    );
  }

  getFile<T>(
    url: string,
    config: object = {},
    addCreds: boolean = true
  ): Observable<T> {
    return this.httpClient.get<T>(url, this.mergeFileConfig(config, addCreds));
  }

  client(): HttpClient {
    return this.httpClient;
  }

  getBaseUrl(): string {
    return this.baseUrl;
  }

  private mergeConfig(config, addCreds): object {
    const defaults = {
      observe: "body",
      //withCredentials: true,
      responseType: "json",
      "Content-Type": "application/json; charset=utf-8",
    };
    if (addCreds) {
      const credsConfig = { withCredentials: true };
      return { ...defaults, ...credsConfig, ...config };
    }

    return { ...defaults, ...config };
  }

  private mergeFileConfig(config, addCreds): object {
    const defaults = {
      responseType: "blob",
      observe: "response",
      withCredentials: true,
      "Access-Control-Allow-Origin": "*",
    };

    return { ...defaults, ...config };
  }
}
