import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ChartConfiguration, ChartType, ChartData, ChartEvent } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import DataLabelsPlugin from "chartjs-plugin-datalabels";

@Component({
  selector: "app-dashboards-bar-chart",
  templateUrl: "./bar-chart.component.html",
  styleUrls: ["./bar-chart.component.scss"],
})
export class BarChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  @Input() labels: any[] = [];
  @Input() dataset: any[] = [];
  @Input() horizontal: boolean = false;

  public chartOptions: ChartConfiguration["options"] = {
    // indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: true,
    // aspectRatio: 4,
    // We use these empty structures as placeholders for dynamic theming.
    // scales: {
    //   x: {},
    //   y: {
    //     min: 3,
    //   },
    // },
    scales: {
      y: {
          beginAtZero: true
      },
      x: {
        ticks: {
          autoSkip: false
        }
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: "end",
        align: "end",
      },
    },
  };
  public chartType: ChartType = "bar";
  public chartPlugins = [];

  public chartData: ChartData<"bar"> = {
    labels: this.labels,
    datasets: this.dataset,
  };

  constructor() {}

  ngOnInit(): void {
    if (this.horizontal) this.chartOptions["indexAxis"] = 'y';
    this.chartData = {
      labels: this.labels,
      datasets: this.dataset,
    };
    //this.chart.update();
  }

  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: {}[];
  }): void {
    console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: {}[];
  }): void {
    console.log(event, active);
  }

  public randomize(): void {
    // Only Change 3 values
    this.chartData.datasets[0].data = [
      Math.round(Math.random() * 100),
      59,
      80,
      Math.round(Math.random() * 100),
      56,
      Math.round(Math.random() * 100),
      40,
    ];

    this.chart?.update();
  }
}
