import { RouteInfo } from "./vertical-menu.metadata";

// Sidebar menu Routes and data
export const ADMIN_ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "Dashboard",
    icon: "fa-solid fa-gauge",
    class: "has-sub",
    permission: [],
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/dashboards/income-statement",
        title: "Income Statement",
        icon: "fa-solid fa-chart-line submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/dashboards/companies",
        title: "Company Statistics",
        icon: "fa-solid fa-building submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/dashboards/hr",
        title: "Human Resources Statistics",
        icon: "fa-solid fa-ranking-star submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/dashboards/shifts",
        title: "Shifts Statistics",
        icon: "fa-solid fa-magnifying-glass-chart submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/dashboards/closings",
        title: "Closings Statistics",
        icon: "fa-solid fa-chart-pie submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "General",
    icon: "ft-globe",
    class: "has-sub",
    permission: [],
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/companies",
        title: "Companies",
        icon: "fa-solid fa-building submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/data-imports",
        title: "Data Imports",
        icon: "fa-solid fa-cloud-arrow-up submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/preferences",
        title: "Preferences",
        icon: "fa-solid fa-gear submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/groups",
        title: "Groups & Permissions",
        icon: "fa-solid fa-people-group submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "TGS",
    icon: "fa-solid fa-server",
    class: "has-sub",
    permission: [],
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/tgs-categories",
        title: "TGS Categories",
        icon: "fa-solid fa-layer-group submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      }
    ],
  },
  {
    path: "",
    title: "Tasks",
    icon: "fa-solid fa-walkie-talkie",
    class: "has-sub",
    permission: [],
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/tasks",
        title: "Tasks",
        icon: "fa-solid fa-list-check submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/task-schedules",
        title: "Task Schedules",
        icon: "fa-solid fa-briefcase submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Shift Schedules",
    icon: "fa-solid fa-calendar-days",
    class: "has-sub",
    permission: [],
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/presences",
        title: "Attendance Sheet",
        icon: "fa-solid fa-certificate submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/calendar",
        title: "Calendar",
        icon: "fa-solid fa-calendar submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/holidays",
        title: "Holidays",
        icon: "fa-solid fa-plane submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/shifts",
        title: "Shifts",
        icon: "fa-solid fa-calendar-check submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Staff",
    icon: "fa-solid fa-users",
    class: "has-sub",
    permission: [],
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/employees",
        title: "Employee List",
        icon: "fa-solid fa-address-book submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/bonuses",
        title: "Bonuses",
        icon: "fa-solid fa-coins submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/bonus-maluses",
        title: "Bonus Maluses",
        icon: "fa-solid fa-gift submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/employee-costs",
        title: "Employee Costs",
        icon: "fa-solid fa-sack-dollar submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/paychecks",
        title: "Paychecks",
        icon: "fa-solid fa-envelope-open-text submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/resignations",
        title: "Resignations",
        icon: "fa-solid fa-box-archive submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Human Resources",
    icon: "fa-solid fa-users-viewfinder",
    class: "has-sub",
    permission: [],
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/candidates",
        title: "Candidates",
        icon: "fa-solid fa-id-card submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/jobs",
        title: "Jobs",
        icon: "fa-solid fa-briefcase submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Closings",
    icon: "fa-solid fa-calculator",
    class: "has-sub",
    permission: [],
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/closings/new",
        title: "New Closing",
        icon: "ft-briefcase submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/closings",
        title: "Closing List",
        icon: "fa-solid fa-list-alt submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/closing-fields",
        title: "Closing Fields",
        icon: "fa-solid fa-th submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Borderò",
    icon: "fa-solid fa-file-invoice",
    class: "has-sub",
    permission: [],
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/bordero",
        title: "Borderò List",
        icon: "fa-solid fa-list-alt submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/bordero/providers",
        title: "Borderò Provider List",
        icon: "fa-solid fa-list-alt submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/bordero/services",
        title: "Borderò Service List",
        icon: "fa-solid fa-list-alt submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "/comunications",
    title: "Comunications",
    icon: "ft-bell",
    class: "",
    permission: [],
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
];

export const EMPLOYEE_ROUTES: RouteInfo[] = [
  {
    path: "/dashboards/my",
    title: "Dashboard",
    icon: "ft-home",
    class: "",
    permission: [],
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/stamping",
    title: "Stamping",
    icon: "fa-solid fa-stamp",
    class: "",
    permission: [],
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/tasks",
    title: "Tasks",
    icon: "fa-solid fa-list-check",
    class: "",
    permission: [],
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "",
    title: "Shift Schedules",
    icon: "fa-solid fa-calendar-days",
    class: "has-sub",
    permission: [],
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/calendar",
        title: "Calendar",
        icon: "fa-solid fa-calendar submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/holidays",
        title: "Holidays",
        icon: "fa-solid fa-plane submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Staff",
    icon: "fa-solid fa-users",
    class: "has-sub",
    permission: [],
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/paychecks",
        title: "Paychecks",
        icon: "fa-solid fa-envelope-open-text submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/resignation",
        title: "Resignation",
        icon: "fa-solid fa-box-archive submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Closings",
    icon: "fa-solid fa-calculator",
    class: "has-sub",
    permission: [],
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/closings/new",
        title: "New Closing",
        icon: "ft-briefcase submenu-icon",
        class: "",
        permission: [],
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "/comunications",
    title: "Comunications",
    icon: "ft-bell",
    class: "",
    permission: [],
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
];

export const ZOMBIE_ROUTES: RouteInfo[] = [
  {
    path: "/stampings",
    title: "Stampings",
    icon: "fa-solid fa-stamp",
    class: "",
    permission: [],
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
];
