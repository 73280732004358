<div class="row justify-content-md-center">
    <div class="col-md-auto">
        <fieldset class="form-group">
            <label>{{ 'Show'| translate }}
                <select (change)="onLimitChange($event.target.value)"
                    class="form-control d-inline-block width-100 p-right-25 pl-4 mx-1">
                    <option *ngFor="let option of pageLimitOptions" [value]="option.value"
                        [selected]="option.value == currentPageLimit">
                        {{ option.value }}
                    </option>
                </select>
                {{ 'per page'| translate }}
            </label>
        </fieldset>
    </div>
    <div class="col-md-auto">
        <ng-select [multiple]="true" [items]="allRemovableColumns" [closeOnSelect]="false"
            (change)="onSelectColumnChange($event)" (blur)="onSelectColumnTouched()"
            [compareWith]="compareSelectColumnsFn" [placeholder]="'Select Columns to view' | translate" bindLabel="name"
            [ngModel]="selectedColumns" bindValue="id">

            <ng-template ng-header-tmp let-items="items">
                <input type="checkbox" [ngModel]="selectAllColumns" (change)="toggleCheckAll($event)" />
            </ng-template>

            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngFor="let item of items | slice:0:2">
                    <span class="ng-value-label">{{ item.name | translate }}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </div>
                <div class="ng-value" *ngIf="items.length > 2">
                    <span class="ng-value-label">{{items.length - 2}} {{ 'more' | translate }}...</span>
                </div>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> {{ item.name | translate }}
            </ng-template>
        </ng-select>
    </div>
    <div class="col-md-auto">
        <div class="input-group" *ngIf="selectedRows.length > 0">
            <select class="form-control" (change)="onSelectActionChange($event.target.value)">
                <option value="">---</option>
                <option value="1">{{ 'Delete' | translate }}</option>
            </select>
            <div class="input-group-append">
                <button class="btn btn-secondary" type="button" [disabled]="selectedAction === ''"
                    (click)="onActionSelected()">
                    {{ 'Apply'| translate }}
                </button>
            </div>
        </div>
    </div>
    <div class="col text-right">
        <ng-container *ngFor="let action of customActions">
            <button class="btn btn-{{ action.type }} mr-2" *ngxPermissionsOnly="action.permissions"
                (click)="action.action()">
                <span [innerHTML]="action.icon"></span>
                {{ action.name | translate }}
            </button>
        </ng-container>
    </div>
</div>

<ngx-datatable class="bootstrap core-bootstrap" [columns]="columns" [columnMode]="'force'" [rows]="gridModel.Data"
    [headerHeight]="'auto'" [footerHeight]="'auto'" [rowHeight]="'auto'" [count]="gridModel?.TotalElements"
    [offset]="gridModel?.CurrentPageNumber" [limit]="gridModel?.PageSize" (page)="loadPage($event)"
    (sort)="onSort($event)" [loadingIndicator]="isLoading" [externalPaging]="true" [externalSorting]="true"
    [reorderable]="true" [selected]="selectedRows" [selectionType]="'checkbox'" (select)="onSelect($event)"
    (activate)="onActivate($event)"
    [messages]="{emptyMessage: 'No data to display' | translate, totalMessage: 'Total' | translate, selectedMessage: false}"
    [scrollbarH]="true">
</ngx-datatable>

<ng-template #translateColumnTemplate let-column="column">{{ column.name | translate }}</ng-template>
<ng-template #checkboxColumnTemplate let-value="value" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
    <input type="checkbox" [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)" />
</ng-template>
<ng-template #checkboxCellTemplate let-value="value" let-isSelected="isSelected"
    let-onCheckboxChangeFn="onCheckboxChangeFn">
    <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)" />
</ng-template>


<ng-template #inlineEditingActionsTemplate let-row="row" let-value="value">
    <!-- Pencil icon -->
    <i class="fa-solid fa-pencil text-primary cursor-pointer mr-2" title="Modify" *ngIf="!this.isEditable[value]"
        (click)="this.isEditable[value]=!this.isEditable[value]"></i>

    <!-- Save/Delete icons -->
    <i class="fa-solid fa-floppy-disk text-primary cursor-pointer mr-2" *ngIf="this.isEditable[value]" title="Save"
        (click)="save(row, value)"></i>
    <i class="fa-solid fa-ban text-primary cursor-pointer mr-2" *ngIf="this.isEditable[value]" title="Delete"
        (click)="delete(row, value)"></i>
</ng-template>

<ng-template #actionsTemplate let-row="row" let-value="value">
    <ng-container *ngFor="let action of rowActions">
        <ng-container *ngIf="action.visible($event, row)">
            <ng-template [ngxPermissionsOnly]="action.permissions" [ngxPermissionsExcept]="action.permissionExcepts">
                <i class="{{ action.icon }} text-{{ action.type }} cursor-pointer mr-2" container="body" placement="top"
                    ngbTooltip="{{ action.name | translate }}" (click)="action.action($event, row, value)"></i>
            </ng-template>
        </ng-container>
    </ng-container>
</ng-template>


<ng-template #emptyTemplate let-row="row" let-value="value"></ng-template>
<ng-template #valueTemplate let-row="row" let-value="value">{{ value }}</ng-template>
<ng-template #translateTemplate let-row="row" let-value="value">{{ value | translate }}</ng-template>
<ng-template #currencyTemplate let-row="row" let-value="value">{{ value | currency: 'EUR' }}</ng-template>
<ng-template #dateTemplate let-row="row" let-value="value">{{ value | date:'dd/MM/yyyy' }}</ng-template>
<ng-template #dateTimeTemplate let-row="row" let-value="value">{{ value | date:'dd/MM/yyyy HH:MM:SS' }}</ng-template>
<ng-template #selectOptionsTemplate let-row="row" let-value="value" let-column="column">
    {{ findOptionById(column.options, value).label | translate }}
</ng-template>
<ng-template #booleanTemplate let-row="row" let-value="value">
    <i
        class="{{ value ? 'fa-solid fa-check fa-2x text-success' : 'fa-solid fa-exclamation-triangle fa-2x text-danger' }}"></i>
</ng-template>
<ng-template #downloadTemplate let-row="row" let-value="value">
    <a *ngIf="value" [href]="value" target="_blank">
        <i class="fa-solid fa-download fa-2x"></i>
    </a>
    <i *ngIf="!value" class="fa-solid fa-triangle-exclamation fa-2x text-danger"></i>
</ng-template>
<ng-template #linkTemplate let-row="row" let-value="value">
    <a *ngIf="value" [href]="value" target="_blank">
        <i class="fa-solid fa-link fa-2x"></i>
    </a>
    <i *ngIf="!value" class="fa-solid fa-triangle-exclamation fa-2x text-danger"></i>
</ng-template>
<ng-template #chipsTemplate let-row="row" let-value="value">
    <ng-template ngFor let-obj [ngForOf]="value">
        <span *ngIf="obj" class="badge badge-pill badge-info mb-1 mr-2">{{ obj.title }}</span>
    </ng-template>
</ng-template>
<!-- 
<ng-template #avatarTemplate let-row="row" let-value="value">
    <div class="d-flex align-items-center">
      <div *ngIf="value; else customAvatar">
        <img class="rounded-circle mr-1" [src]="value" height="32" width="32" alt="datatable-avatar" />
      </div>
      <ng-template #customAvatar>
        <div class="avatar mr-1 ml-0 bg-info">
          <div class="avatar-content">{{ name | shortName }}</div>
        </div>
      </ng-template>
      <div class="cell-line-height">
        <p class="mb-0">{{ name }}</p>
      </div>
    </div>
</ng-template>
 -->