import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OverlayModule } from "@angular/cdk/overlay";

import { NgbDatepickerModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ClickOutsideModule } from "ng-click-outside";

import { AutocompleteModule } from "./components/autocomplete/autocomplete.module";
import { PipeModule } from "app/shared/pipes/pipe.module";
import { UiSwitchModule } from "ngx-ui-switch";
import { PdfViewerModule } from "ng2-pdf-viewer";

// COMPONENTS
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { HorizontalMenuComponent } from "./horizontal-menu/horizontal-menu.component";
import { VerticalMenuComponent } from "./vertical-menu/vertical-menu.component";
import { CustomizerComponent } from "./customizer/customizer.component";
import { NotificationSidebarComponent } from "./notification-sidebar/notification-sidebar.component";

// DIRECTIVES
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { SidebarLinkDirective } from "./directives/sidebar-link.directive";
import { SidebarDropdownDirective } from "./directives/sidebar-dropdown.directive";
import { SidebarAnchorToggleDirective } from "./directives/sidebar-anchor-toggle.directive";
import { SidebarDirective } from "./directives/sidebar.directive";
import { TopMenuDirective } from "./directives/topmenu.directive";
import { TopMenuLinkDirective } from "./directives/topmenu-link.directive";
import { TopMenuDropdownDirective } from "./directives/topmenu-dropdown.directive";
import { TopMenuAnchorToggleDirective } from "./directives/topmenu-anchor-toggle.directive";
import { PdfViewerComponent } from "./components/pdf-viewer/pdf-viewer.component";
import { CardClosingComponent } from "./components/card-closing/card-closing.component";
import { NgxPermissionsModule } from "ngx-permissions";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { DashboardFiltersComponent } from "./components/dashboard-filters/dashboard-filters.component";
import { PolarAreaChartComponent } from "./components/dashboards/polar-area-chart/polar-area-chart.component";
import { LineChartComponent } from "./components/dashboards/line-chart/line-chart.component";
import { BarChartComponent } from "./components/dashboards/bar-chart/bar-chart.component";
import { DoughnutChartComponent } from "./components/dashboards/doughnut-chart/doughnut-chart.component";
import { RadarChartComponent } from "./components/dashboards/radar-chart/radar-chart.component";
import { PieChartComponent } from "./components/dashboards/pie-chart/pie-chart.component";
import { NgChartsModule } from "ng2-charts";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { WeeklyShiftTemplateComponent } from "./components/weekly-shift-template/weekly-shift-template.component";
import { ModalComponent } from "./components/modal/modal.component";
import { DatatableComponent } from "./components/datatable/datatable.component";
import { TagInputModule } from "ngx-chips";
import { HttpClient } from "@angular/common/http";
import { createTranslateLoader } from "app/app.module";
import { WhenEmptyDirective } from "./directives/when-empty.directive";
import { DatatableFiltersComponent } from "./components/datatable-filters/datatable-filters.component";
import { FormlyModule, FORMLY_CONFIG } from "@ngx-formly/core";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { FormlySelectModule } from "@ngx-formly/core/select";
import { SignaturePadModule } from "angular2-signaturepad";
import { NouisliderModule } from "ng2-nouislider";
import { FORM_CONFIG } from './components/formly/formly-config.model';
import { FormlyFieldChips } from "./components/formly/types/field-chips/field-chips.component";
import { FormlyFieldDatePicker } from "./components/formly/types/field-datepicker/field-datepicker.component";
import { FormlyFieldFile } from "./components/formly/types/field-file/file-type.component";
import { FormlyFieldModalInputModal } from "./components/formly/types/field-modal-input/field-modal-input.component";
import { FormlyFieldOne2Many } from "./components/formly/types/field-one2many/field-one2many.component";
import { FormlyFieldPassword } from "./components/formly/types/field-password/field-password.component";
import { FormlyFieldPhoto } from "./components/formly/types/field-photo/field-photo.component";
import { FormlyFieldRating } from "./components/formly/types/field-rating/field-rating.component";
import { FormlyFieldCustomSelect } from "./components/formly/types/field-select/field-select.component";
import { FormlyFieldSelect2 } from "./components/formly/types/field-select2/field-select2.component";
import { FormlyFieldSignature } from "./components/formly/types/field-signature/field-signature.component";
import { FormlyFieldSlider } from "./components/formly/types/field-slider/field-slider.component";
import { FormlyFieldSwitch } from "./components/formly/types/field-switch/field-switch.component";
import { FormlyFieldTabs } from "./components/formly/types/field-tabs/field-tabs.component";
import { CardWrapperComponent } from "./components/formly/wrappers/card-wrapper.component";
import { ModalWrapperComponent } from "./components/formly/wrappers/modal-wrapper.component";
import { registerTranslateExtension } from "./components/formly/helpers/translate.extension";
import { ArchwizardModule } from "angular-archwizard";
import { FormlyFieldNav } from "./components/formly/types/field-nav/field-nav.component";
import { FormlyFieldWizard } from "./components/formly/types/field-wizard/field-wizard.component";
import { FileValueAccessor } from "./components/formly/types/field-file/file-value-accessor";
import { FormlyFieldButton } from "./components/formly/types/field-button/field-button.type";

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxPermissionsModule.forChild(),
    OverlayModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    AutocompleteModule,
    PipeModule,
    PdfViewerModule,
    NgxDatatableModule,
    NgChartsModule,
    NgxIntlTelInputModule,
    ArchwizardModule,
    
    ReactiveFormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    TagInputModule,
    NouisliderModule,
    UiSwitchModule,
    SignaturePadModule,
    FormlyBootstrapModule,
    FormlySelectModule,
    FormlyModule.forRoot(FORM_CONFIG),
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    CustomizerComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    SidebarLinkDirective,
    SidebarDropdownDirective,
    SidebarAnchorToggleDirective,
    SidebarDirective,
    TopMenuLinkDirective,
    TopMenuDropdownDirective,
    TopMenuAnchorToggleDirective,
    TopMenuDirective,
    WhenEmptyDirective,
    PdfViewerComponent,
    ModalComponent,
    DatatableComponent,
    CardClosingComponent,
    DashboardFiltersComponent,
    WeeklyShiftTemplateComponent,
    PolarAreaChartComponent,
    LineChartComponent,
    BarChartComponent,
    DoughnutChartComponent,
    RadarChartComponent,
    PieChartComponent,
   
    CardWrapperComponent,
    ModalWrapperComponent,
    FormlyFieldPassword,
    FormlyFieldSignature,
    FormlyFieldChips,
    FormlyFieldDatePicker,
    FileValueAccessor,
    FormlyFieldFile,
    FormlyFieldPhoto,
    FormlyFieldRating,
    FormlyFieldSlider,
    FormlyFieldSwitch,
    FormlyFieldCustomSelect,
    FormlyFieldSelect2,
    FormlyFieldButton,
    FormlyFieldModalInputModal,
    FormlyFieldTabs,
    FormlyFieldNav,
    FormlyFieldWizard,
    FormlyFieldOne2Many,

    DatatableFiltersComponent,
  ],
  exports: [
    CommonModule,
    FooterComponent,
    NavbarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    CustomizerComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    SidebarDirective,
    TopMenuDirective,
    NgbModule,
    TranslateModule,
    PdfViewerComponent,
    ModalComponent,
    DatatableComponent,
    DatatableFiltersComponent,
    CardClosingComponent,
    DashboardFiltersComponent,
    WeeklyShiftTemplateComponent,
    PolarAreaChartComponent,
    LineChartComponent,
    BarChartComponent,
    DoughnutChartComponent,
    RadarChartComponent,
    PieChartComponent,
    
    FormlyModule,
    CardWrapperComponent,
    ModalWrapperComponent,
    FormlyFieldPassword,
    FormlyFieldSignature,
    FormlyFieldChips,
    FormlyFieldDatePicker,
    FileValueAccessor,
    FormlyFieldFile,
    FormlyFieldPhoto,
    FormlyFieldRating,
    FormlyFieldSlider,
    FormlyFieldSwitch,
    FormlyFieldCustomSelect,
    FormlyFieldSelect2,
    FormlyFieldButton,
    FormlyFieldModalInputModal,
    FormlyFieldTabs,
    FormlyFieldNav,
    FormlyFieldWizard,
    FormlyFieldOne2Many,
  ],
  providers: [
    DatePipe,
    { provide: FORMLY_CONFIG, multi: true, useFactory: registerTranslateExtension, deps: [TranslateService] }
  ],
})
export class SharedModule {}
