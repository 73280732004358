import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Chart, ChartConfiguration, ChartType, ChartEvent } from "chart.js";
import Annotation from "chartjs-plugin-annotation";
import { BaseChartDirective } from "ng2-charts";

@Component({
  selector: "app-dashboards-line-chart",
  templateUrl: "./line-chart.component.html",
  styleUrls: ["./line-chart.component.scss"],
})
export class LineChartComponent implements OnInit {
  //@ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  @Input() labels: any[] = ["January", "February", "March", "April", "May", "June", "July"];
  @Input() dataset: any[] = [
    {
      data: [65, 59, 80, 81, 56, 55, 40],
      label: "Series A",
      backgroundColor: "rgba(148,159,177,0.2)",
      borderColor: "rgba(148,159,177,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
      fill: "origin",
    },
    {
      data: [28, 48, 40, 19, 86, 27, 90],
      label: "Series B",
      backgroundColor: "rgba(77,83,96,0.2)",
      borderColor: "rgba(77,83,96,1)",
      pointBackgroundColor: "rgba(77,83,96,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(77,83,96,1)",
      fill: "origin",
    },
    {
      data: [180, 480, 770, 90, 1000, 270, 400],
      label: "Series C",
      yAxisID: "y1",
      backgroundColor: "rgba(255,0,0,0.3)",
      borderColor: "red",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
      fill: "origin",
    },
  ];

  @Input() newLabel? = "New label";

  constructor() {
    Chart.register(Annotation);
  }

  ngOnInit(): void {}
  public chartData: ChartConfiguration["data"] = {
    datasets: this.dataset,
    labels: this.labels,
  };

  public chartOptions: ChartConfiguration["options"] = {
    elements: {
      line: {
        tension: 0.5,
      },
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      y: {
        position: "left",
      },
      y1: {
        position: "right",
        grid: {
          color: "rgba(255,0,0,0.3)",
        },
        ticks: {
          color: "red",
        },
      },
    },

    plugins: {
      legend: { display: true },
      annotation: {
        annotations: [
          {
            type: "line",
            scaleID: "x",
            value: "March",
            borderColor: "orange",
            borderWidth: 2,
            label: {
              display: true,
              position: "center",
              color: "orange",
              content: "LineAnno",
              font: {
                weight: "bold",
              },
            },
          },
        ],
      },
    },
  };

  public chartType: ChartType = "line";

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  private static generateNumber(i: number): number {
    return Math.floor(Math.random() * (i < 2 ? 100 : 1000) + 1);
  }

  public randomize(): void {
    for (let i = 0; i < this.chartData.datasets.length; i++) {
      for (let j = 0; j < this.chartData.datasets[i].data.length; j++) {
        this.chartData.datasets[i].data[j] =
          LineChartComponent.generateNumber(i);
      }
    }
    this.chart?.update();
  }

  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: {}[];
  }): void {
    console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: {}[];
  }): void {
    console.log(event, active);
  }

  public hideOne(): void {
    const isHidden = this.chart?.isDatasetHidden(1);
    this.chart?.hideDataset(1, !isHidden);
  }

  public pushOne(): void {
    this.chartData.datasets.forEach((x, i) => {
      const num = LineChartComponent.generateNumber(i);
      x.data.push(num);
    });
    this.chartData?.labels?.push(
      `Label ${this.chartData.labels.length}`
    );

    this.chart?.update();
  }

  public changeColor(): void {
    this.chartData.datasets[2].borderColor = "green";
    this.chartData.datasets[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;

    this.chart?.update();
  }

  public changeLabel(): void {
    const tmp = this.newLabel;
    this.newLabel = this.chartData.datasets[2].label;
    this.chartData.datasets[2].label = tmp;

    this.chart?.update();
  }
}
