import { Component } from "@angular/core";
import {
  FieldType,
  FieldTypeConfig,
  FormlyFieldConfig,
} from "@ngx-formly/core";

@Component({
  selector: "formly-field-wizard",
  template: `
    <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
      <aw-wizard-step
        [stepTitle]="step.props.label"
        [navigationSymbol]="{ symbol: index + 1 }"
        *ngFor="
          let step of field.fieldGroup;
          let index = index;
          let last = last
        "
      >
        <formly-field [field]="step"></formly-field>
        <div class="form-group text-center space-20">
          <button
            *ngIf="index !== 0"
            uiSref="personal"
            type="button"
            class="btn btn-secondary mr-1"
            awPreviousStep
          >
            <span style="margin-right:10px;">
              <i class="ft-chevron-left"></i>
            </span>
            {{ "Previous" | translate }}
          </button>

          <button
            *ngIf="!last"
            uiSref="address"
            type="button"
            class="btn btn-info"
            [disabled]="!form.valid"
            awNextStep
          >
            {{ "Next" | translate }}
            <span style="margin-left:10px;">
              <i class="ft-chevron-right"></i>
            </span>
          </button>

          <button
            *ngIf="last"
            uiSref="result"
            type="button"
            class="btn btn-success"
            [disabled]="!form.valid"
            awResetWizard
          >
            {{ "Finish" | translate }}
            <span style="margin-left:10px;">
              <i class="ft-chevron-right"></i>
            </span>
          </button>
        </div>
      </aw-wizard-step>
    </aw-wizard>
  `,
})
export class FormlyFieldWizard extends FieldType<FieldTypeConfig> {
  active = 0;

  isValid(field: FormlyFieldConfig): boolean {
    if (field.key) {
      return field.formControl.valid;
    }

    return field.fieldGroup
      ? field.fieldGroup.every((f) => this.isValid(f))
      : true;
  }
}
