import {
  Component,
  Injectable,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";

@Injectable()
@Component({
  selector: "app-datatable-filters",
  templateUrl: "./datatable-filters.component.html",
  styleUrls: ["./datatable-filters.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DatatableFiltersComponent {
  @Input() form = new FormGroup({});
  @Input() options: FormlyFormOptions = {};
  @Input() model: any = {};
  @Input() fields: FormlyFieldConfig[] = [];

  @Output() onSubmitRequested = new EventEmitter<any>();
  @Output() onResetRequested = new EventEmitter<any>();


  onSubmit() {
    if (!this.form.valid) return false;
    this.onSubmitRequested.emit({});
  }

  onReset() {
    this.form.reset();
    this.onResetRequested.emit();
  }


  // @Input() model: {};
  // public dynamicFormGroup: FormGroup;
  // public fields = [];

  // @Output() onSubmitRequested = new EventEmitter<any>();
  // @Output() onResetRequested = new EventEmitter<any>();

  // ngOnInit() {
  //   this.buildForm();
  // }

  // get f() {
  //   return this.dynamicFormGroup.controls;
  // }

  // private buildForm() {
  //   const formGroupFields = this.getFormControlsFields();
  //   this.dynamicFormGroup = new FormGroup(formGroupFields);
  // }

  // public rebuildForm() {
  //   this.fields = [];
  //   this.buildForm();
  // }

  // private getFormControlsFields() {
  //   const formGroupFields = {};
  //   for (const field of Object.keys(this.model)) {
  //     const fieldProps = this.model[field];

  //     formGroupFields[field] = new FormControl(fieldProps.value);
  //     this.fields.push({ ...fieldProps, fieldName: field });
  //   }

  //   return formGroupFields;
  // }

  // onSubmit() {
  //   this.onSubmitRequested.emit(this.dynamicFormGroup.getRawValue());
  // }

  // onReset() {
  //   this.dynamicFormGroup.reset();
  //   this.onResetRequested.emit();
  // }

  // ngOnDestroy(): void {
  //   // this.fields = [];
  // }
}
