import { Component } from "@angular/core";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";

@Component({
  selector: "formly-field-password",
  template: `
    <div class="form-group">
      <label>
          {{ to.label }}
          <span *ngIf="to.required">*</span>
      </label>
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text" (click)="showPsw = !showPsw">
            <i
              class="fa-solid"
              [ngClass]="{
                'fa-eye': showPsw,
                'fa-eye-slash': !showPsw
              }"
              aria-hidden="true"
            ></i>
          </div>
        </div>
        <input
          [type]="showPsw ? 'text' : 'password'"
          [formControl]="formControl"
          [formlyAttributes]="field"
          class="form-control"
          placeholder="{{ to.placeholder }}"
          autocomplete="off"
        />
      </div>
      <div></div>
    </div>
  `,
})
export class FormlyFieldPassword extends FieldType<FieldTypeConfig> {
  showPsw = false;
}
