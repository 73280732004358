//#region
//
// Installation
//   npm install encrypt-storage
//
// Service Usage
//   import { SessionStorageService } from 'src/app/features/services/session-storage.service';
//
//#endregion

import { Injectable } from '@angular/core';
import { EncryptStorage } from 'encrypt-storage';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  encryptStorage;

  constructor() {
    this.encryptStorage = new EncryptStorage(environment.encryptStorageSecretKey, {
      storageType: 'sessionStorage',
      stateManagementUse: true,
    });
  }

  public setItem(key: string, value: string) {
    this.encryptStorage.setItem(key, value);
  }

  public getItem(key: string) {
    return this.encryptStorage.getItem(key)
  }
  
  public removeItem(key: string) {
    this.encryptStorage.removeItem(key);
  }

  public clearData() {
    this.encryptStorage.clear();
  }

}