import { Component, ViewContainerRef, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import {
  Router,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  NavigationStart,
} from "@angular/router";
import { filter } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "./shared/api/auth.service";

const TOKEN_SHARING_CHANNEL = "token-sharing";
const REQUESTING_TOKEN = "requesting-token";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  loading = false;

  bc = new BroadcastChannel(TOKEN_SHARING_CHANNEL);

  constructor(
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private authService: AuthService
  ) {
    this.addBroadcastChannelListener();
    this.bc.postMessage(REQUESTING_TOKEN);
  }

  ngOnInit() {
    this.subscription = this.router.events
      .pipe(
        filter((event) => {
          switch (true) {
            case event instanceof NavigationStart: {
              this.loading = true;
              this.spinnerService.show();
              break;
            }
            case event instanceof NavigationEnd:
            case event instanceof NavigationCancel: {
              setTimeout(() => {
                this.loading = false;
                this.spinnerService.hide();
              }, 20000);
            }
            case event instanceof NavigationError: {
              setTimeout(() => {
                this.loading = false;
                this.spinnerService.hide();
              }, 20000);
              break;
            }
            default: {
              break;
            }
          }
          return event instanceof NavigationEnd;
        })
      )
      .subscribe(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private addBroadcastChannelListener() {
    this.bc.addEventListener("message", (event) => {
      if (event.data === REQUESTING_TOKEN) {
        new BroadcastChannel(TOKEN_SHARING_CHANNEL).postMessage({
          accessToken: this.authService.getAccessToken(),
          refreshToken: this.authService.getRefreshToken(),
          user: this.authService.getUser(false),
        });
      } else {
        const { accessToken, refreshToken, user } = event.data;
        accessToken && this.authService.saveAccessToken(accessToken);
        refreshToken && this.authService.saveRefreshToken(refreshToken);
        user && this.authService.setUser(user);
      }
    });
  }
}
