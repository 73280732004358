import { Component, ViewChild, NgZone, ChangeDetectionStrategy } from '@angular/core';
import { SelectControlValueAccessor } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'formly-field-custom-select',
  template: `
        <select *ngIf="to.multiple; else singleSelect" class="form-control"
        multiple
        [class.custom-select]="to.customSelect"
        [formControl]="formControl"
        [compareWith]="to.compareWith || compareWith"
        [class.is-invalid]="showError"
        [formlyAttributes]="field">
        <ng-container *ngIf="to.options | formlySelectOptions:field | async as opts">
          <ng-container *ngIf="to._flatOptions else grouplist">
            <ng-container *ngFor="let opt of opts">
              <option [ngValue]="opt.value" [disabled]="opt.disabled">{{ opt.label }}</option>
            </ng-container>
          </ng-container>
          <ng-template #grouplist>
            <ng-container *ngFor="let opt of opts">
              <option *ngIf="!opt.group else optgroup" [ngValue]="opt.value" [disabled]="opt.disabled">{{ opt.label }}</option>
              <ng-template #optgroup>
                <optgroup [label]="opt.label">
                  <option *ngFor="let child of opt.group" [ngValue]="child.value" [disabled]="child.disabled">
                    {{ child.label }}
                  </option>
                </optgroup>
              </ng-template>
            </ng-container>
          </ng-template>
        </ng-container>
      </select>
      <ng-template #singleSelect>
        <select class="form-control"
          [formControl]="formControl"
          [compareWith]="to.compareWith || compareWith"
          [class.custom-select]="to.customSelect"
          [class.is-invalid]="showError"
          [formlyAttributes]="field" style="display: inline-block; width: 96%;">
          <option *ngIf="to.placeholder" [ngValue]="null">{{ to.placeholder }}</option>
          <ng-container *ngIf="to.options | formlySelectOptions:field | async as opts">
            <ng-container *ngIf="to._flatOptions else grouplist">
              <ng-container *ngFor="let opt of opts">
                <option [ngValue]="opt.value" [disabled]="opt.disabled">{{ opt.label }}</option>
              </ng-container>
            </ng-container>
            <ng-template #grouplist>
              <ng-container *ngFor="let opt of opts">
                <option *ngIf="!opt.group else optgroup" [ngValue]="opt.value" [disabled]="opt.disabled">{{ opt.label }}</option>
                <ng-template #optgroup>
                  <optgroup [label]="opt.label">
                    <option *ngFor="let child of opt.group" [ngValue]="child.value" [disabled]="child.disabled">
                      {{ child.label }}
                    </option>
                  </optgroup>
                </ng-template>
              </ng-container>
            </ng-template>
          </ng-container>
        </select>
        <span *ngIf="!to.required && !to.disabled && to.options && to.options.length > 0" style="position: relative; left: -30px; top: -3px; cursor: pointer;" (click)="clearValue()">x</span>
      </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldCustomSelect extends FieldType {
  defaultOptions = {
    templateOptions: { options: [] },
  };

  // workaround for https://github.com/angular/angular/issues/10010
  @ViewChild(SelectControlValueAccessor) set selectAccessor(s: any) {
    if (!s) return;

    const writeValue = s.writeValue.bind(s);
    if (s._getOptionId(s.value) === null) {
      writeValue(s.value);
    }

    s.writeValue = (value: any) => {
      const id = s._idCounter;
      writeValue(value);
      if (value === null) {
        this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
          if (id !== s._idCounter && s._getOptionId(value) === null && s._elementRef.nativeElement.selectedIndex !== -1) {
            writeValue(value);
          }
        });
      }
    };
  }

  constructor(private ngZone: NgZone) {
    super();
  }

  compareWith(o1: any, o2: any) {
    return o1 === o2;
  }

  clearValue() {
    this.formControl.setValue("");
  }
}