import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { UntypedFormGroup, UntypedFormArray } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class CommonUtilsService {
  constructor(private datePipe: DatePipe) {}

  /**
   * getAllFormErrors
   * 
   * @param form 
   * @returns 
   */
  getAllFormErrors(
    form: UntypedFormGroup | UntypedFormArray
  ): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof UntypedFormGroup ||
        control instanceof UntypedFormArray
          ? this.getAllFormErrors(control)
          : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
  }

  /**
   * convert json object to interface model
   */
  toCamel(s: string) {
    return s.replace(/([-_][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace("-", "").replace("_", "");
    });
  }

  keysToCamel(o: any) {
    if (o === Object(o) && !Array.isArray(o) && typeof o !== "function") {
      const n = {};
      Object.keys(o).forEach((k) => {
        n[this.toCamel(k)] = this.keysToCamel(o[k]);
      });
      return n;
    } else if (Array.isArray(o)) {
      return o.map((i) => {
        return this.keysToCamel(i);
      });
    }
    return o;
  }

  /**
   * convert ngbDatePicker to date
   */
  ngbDatePickerDateToString(ngb) {
    if (ngb && ngb.year) {
      return this.datePipe.transform(new Date(ngb.year, ngb.month-1, ngb.day), 'yyyy-MM-dd');
      // return `${ngb.year}-${this.convertMonthToString(ngb.month)}-${ngb.day}`;
    }

    return undefined;
  }

  /**
   * convert ngbDatePicker to date
   */
  ngbDatePickerDateToDate(ngb) {
    if (ngb) {
      return new Date(ngb.year, ngb.month, ngb.day);
    }

    return undefined;
  }

  /**
   * convert date formatted yyyy-mm-dd to ngbDatePicker
   */
  ngbDatePickerFromDateFormat(ngb) {
    if (ngb) {
      const [year, month, day] = ngb.split("-");
      const obj = {
        year: parseInt(year),
        month: parseInt(month),
        day: parseInt(day.split(" ")[0].trim()),
      };
      return obj;
    }

    return {
      year: "",
      month: "",
      day: "",
    };
  }

  /**
   * convert Blob to File
   */
  blobToFile = (blob: Blob, fileName: string): File => {
    var b: any = blob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>blob;
  };

  base64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  /**
   * Convert month to string
   */
  convertMonthToString(month) {
    if (month < 10) {
      return `0${month}`;
    }

    return month;
  }

  /**
   * Convert value to decimal
   */
  countDecimals(value) {
    if (value.toString().split(".").length < 2) return 0;

    let text = value.toString();
    // verify if number 0.000005 is represented as "5e-6"
    if (text.indexOf("e-") > -1) {
      let [base, trail] = text.split("e-");
      let deg = parseInt(trail, 10);
      return deg;
    }
    // count decimals for number in representation like "0.123456"
    if (Math.floor(value) !== value) {
      return value.toString().split(".")[1].length || 0;
    }
    return 0;
  }
}
