import { UntypedFormGroup } from "@angular/forms";

export function compareShiftTimesValidator(
  controlName: string,
  matchingControlName: string,
  miniumDiffHours: number
) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    
    var today = new Date();
    var dt1 = (new Date(today.getFullYear(), today.getMonth(), today.getDate(), control.value.split(':')[0], control.value.split(':')[1])).getTime();
    var dt2 = (new Date(today.getFullYear(), today.getMonth(), today.getDate(), matchingControl.value.split(':')[0], matchingControl.value.split(':')[1])).getTime();
    var seconds = Math.floor((dt2 - dt1) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    console.log("hours",hours);
    console.log("control",control.value);
    console.log("matchingControl",matchingControl.value);
    console.log("dt1",dt1);
    console.log("dt2",dt2);

    if (control.value === matchingControl.value) {
      matchingControl.setErrors({ equals: true });
    } else if (dt1 > dt2) {
      matchingControl.setErrors({ startGreaterThanEnd: true });
    } else if (hours > miniumDiffHours) {
      matchingControl.setErrors({ overTime: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
