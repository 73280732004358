<!-- Filter starts -->
<div class="row">
    <div class="col-12" *ngIf="showFilters">
        <div class="px-2">
            <form [formGroup]="filterForm" (ngSubmit)="onFilterFormSubmit()">
                <div class="row border rounded py-2 mb-2 mx-n2">

                    <div class="col-12 col-sm-6 col-lg-3">
                        <label for="period">{{ "Choose Period" | translate }}</label>
                        <fieldset class="form-group">
                            <select class="form-control" name="period" formControlName="period"
                                (change)="onChangePeriodSelect($event.target.value)">
                                <option *ngFor="let p of periods" [value]="p.id">
                                    {{ p.label | translate }}
                                </option>
                            </select>
                        </fieldset>
                    </div>

                    <div class="col-12 col-sm-6 col-lg-3">
                        <label for="dpFromDate">{{ "Start Date" | translate }}</label>
                        <div class="dp-hidden position-absolute">
                            <div class="input-group">
                                <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                                    [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                                    [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1" />
                                <ng-template #t let-date let-focused="focused">
                                    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                                        [class.faded]="isHovered(date) || isInside(date)"
                                        (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                        {{ date.day }}
                                    </span>
                                </ng-template>
                            </div>
                        </div>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text" (click)="datepicker.toggle()">
                                    <i class="fa fa-calendar" style="cursor: pointer"></i>
                                </div>
                            </div>
                            <input #dpFromDate class="form-control" placeholder="yyyy-mm-dd" name="dpFromDate"
                                [value]="formatter.format(f.fromDate.value)"
                                (input)="fromDate = validateInput(f.fromDate.value, dpFromDate.value)" />
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 col-lg-3">
                        <label for="dpToDate">{{ "End Date" | translate }}</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text" (click)="datepicker.toggle()">
                                    <i class="fa fa-calendar" style="cursor: pointer"></i>
                                </div>
                            </div>
                            <input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate"
                                [value]="formatter.format(f.toDate.value)"
                                (input)="toDate = validateInput(f.toDate.value, dpToDate.value)" />
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 col-lg-3">
                        <label for="company">{{ "Company" | translate }}</label>
                        <fieldset class="form-group">
                            <ng-select formControlName="company" [items]="companies" bindLabel="name" bindValue="id" [multiple]="multiCompany"
                                [closeOnSelect]="true" placeholder="{{ 'Select Companies' | translate }}" (change)="onChangeCompanySelect($event)">
                            </ng-select>
                        </fieldset>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <button [disabled]="formSubmitDisabled" type="submit"
                            class="btn btn-primary btn-block glow">
                            <i class="fa fa-filter"></i>
                            {{ "Filter" | translate }}
                        </button>
                        <button type="reset" (click)="onFilterFormReset()" class="btn btn-danger btn-block glow">
                            <i class="fa fa-eraser"></i>
                            {{ 'Clear'| translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- Filter ends -->