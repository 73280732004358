import { Component } from "@angular/core";
import {
  FieldType,
  FieldTypeConfig,
  FormlyFieldConfig,
} from "@ngx-formly/core";

@Component({
  selector: "formly-field-tabs",
  template: `
    <div class="form-group">
      <label [for]="field.key">
        {{ to.label }}
        <span *ngIf="to.required">*</span>
      </label>
      <div>
        <ul
          ngbNav
          #tabsNav="ngbNav"
          [id]="field.key"
          [(activeId)]="to.active"
          (navChange)="onNavChange($event)"
          class="nav-tabs"
        >
          <li
            [ngbNavItem]="i"
            *ngFor="let tab of field.fieldGroup; let i = index"
            [disabled]="tab.props.disabled"
          >
            <a ngbNavLink>{{ tab.props.label }}</a>
            <ng-template ngbNavContent>
              <div *ngIf="tab.props.description">
                <div style = "display: flex; justify-content:flex-end">
                  <button
                    type="button"
                    class="btn btn-outline-primary mr-1 mb-1"
                    (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed"
                    aria-controls="collapseDiv"
                  >
                  <i class="fa-solid fa-circle-question"></i>
                  {{ "Help" | translate }}
                  </button>
                </div>
                <div id="collapseDiv" [ngbCollapse]="isCollapsed" class="mt-1">
                  <div [innerHtml]="tab.props.description | translate"></div>
                  <br />
                </div>
              </div>
              <formly-field [field]="tab"></formly-field>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="tabsNav" class="mt-2"></div>
      </div>
    </div>
  `,
})
export class FormlyFieldTabs extends FieldType<FieldTypeConfig> {
  isCollapsed = true;
  active = 0;

  isValid(field: FormlyFieldConfig): boolean {
    if (field.key) {
      return field.formControl.valid;
    }

    return field.fieldGroup
      ? field.fieldGroup.every((f) => this.isValid(f))
      : true;
  }

  onNavChange($event) {
    if (this.props.onNavChange) {
      this.props.onNavChange($event);
    }
  }

  selectActive(value) {
    this.active = value;
  }
}
