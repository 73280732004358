import { Injectable } from "@angular/core";
import { AbstractService } from "./abstract.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Closing } from "../models/closing";
import { SessionStorageService } from "../services/session-storage.service";

@Injectable({
  providedIn: "root",
})
export class ClosingService extends AbstractService {
  private url = "/closings";

  constructor(httpClient: HttpClient, encryptStorage: SessionStorageService) {
    super(httpClient, encryptStorage);
  }

  find(params: any): Observable<Closing[]> {
    return this.get(`${this.url}/`, params);
  }

  retrieve(id: any): Observable<Closing> {
    return this.get(`${this.url}/${id}/`);
  }

  create(data: any): Observable<any> {
    return this.post(`${this.url}/`, data);
  }

  update(id: any, data: any): Observable<any> {
    return this.put(`${this.url}/${id}/`, data);
  }

  remove(id: any): Observable<any> {
    return this.delete(`${this.url}/${id}/`);
  }

  check(id: any, data: any): Observable<any> {
    return this.put(`${this.url}/${id}/check/`, data);
  }

  approve(id: any, data: any): Observable<any> {
    return this.put(`${this.url}/${id}/approve/`, data);
  }

  photoUpload(data: any): Observable<any> {
    return this.post(`${this.url}/photo/upload/`, data);
  }

  deletePhotoUploaded(data: any): Observable<any> {
    return this.post(`${this.url}/photo/delete/`, data);
  }

  /*
  findByTitle(title: any): Observable<Closing[]> {
    return this.get(`${this.url}/?title=${title}`);
  }
*/
}
