export class Counter {
  private value = 0;
  reset() {
    this.value = 0;
  }
  inc() {
    this.value++;
    // return this.value++;
  }
  val(): number {
    return this.value;
  }
}
