import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ChartData, ChartType, ChartEvent, ChartConfiguration } from "chart.js";
import { BaseChartDirective } from "ng2-charts";

@Component({
  selector: "app-dashboards-doughnut-chart",
  templateUrl: "./doughnut-chart.component.html",
  styleUrls: ["./doughnut-chart.component.scss"],
})
export class DoughnutChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  @Input() labels: any[] = [
    "Download Sales",
    "In-Store Sales",
    "Mail-Order Sales",
  ];
  @Input() dataset: any[] = [
    { data: [350, 450, 100] },
    { data: [50, 150, 120] },
    { data: [250, 130, 70] },
  ];

  public chartOptions: ChartConfiguration["options"] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "right",
      }
    },
  };
  public chartData: ChartData<"doughnut"> = {
    labels: this.labels,
    datasets: this.dataset,
  };
  public chartType: ChartType = "doughnut";

  constructor() {}

  ngOnInit(): void {
    //this.chart.options.plugins.legend.position = 'right';
    this.chartData = {
      labels: this.labels,
      datasets: this.dataset,
    };
    //this.chart.update();
  }

  // events
  public chartClicked({
    event,
    active,
  }: {
    event: ChartEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event: ChartEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }
}
