import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-one2many',
  template: `
  <div *ngFor="let field of field.fieldGroup; let i = index" class="row align-items-baseline">
    <formly-field class="col" [field]="field"></formly-field>
    <div class="col-1 d-flex align-items-center">
      <button class="btn btn-danger" type="button" (click)="remove(i)">
      <i class="fa-solid fa-trash"></i>
      </button>
    </div>
  </div>
  <div style="margin:30px 0;">
    <button class="btn btn-primary" type="button" (click)="add()">{{ props.addText }}</button>
  </div>
`,
})
export class FormlyFieldOne2Many  extends FieldArrayType {}