import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpErrorResponse,
  HttpXsrfTokenExtractor,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";

const TOKEN_HEADER_KEY = "Authorization";
const CSRF_TOKEN_HEADER_KEY = "X-XSRF-TOKEN";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private authService: AuthService,
    private router: Router,
    private tokenExtractor: HttpXsrfTokenExtractor
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<Object>> {
    let authReq = req;
    const token = this.authService.getAccessToken();
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }

    // if (!authReq.url.includes("auth/login")) {
    //   let csrfToken = this.tokenExtractor.getToken() as string;
    //   console.log("csrfToken", csrfToken, authReq.url);
    //   if (csrfToken !== null && !authReq.headers.has(CSRF_TOKEN_HEADER_KEY)) {
    //     authReq = this.addCsrfTokenHeader(authReq, csrfToken);
    //   }
    // }

    return next.handle(authReq).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !authReq.url.includes("auth/login") &&
          error.status === 401
        ) {
          return this.handle401Error(authReq, next);
        }

        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const t = this.authService.getRefreshToken();

      if (t)
        return this.authService.refreshToken(t).pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;

            //this.authService.saveAccessToken(token.access);
            this.refreshTokenSubject.next(token.access);
            //this.authService.refreshRolePermissions();

            return next.handle(this.addTokenHeader(request, token.access));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.router.navigate(["/"]);
            return throwError(err);
          })
        );
    }

    if (this.isRefreshing && request.url.includes("token/refresh")) {
      this.isRefreshing = false;

      this.authService.clearData();
      return;
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set(TOKEN_HEADER_KEY, "Bearer " + token),
    });
  }

  private addCsrfTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set(CSRF_TOKEN_HEADER_KEY, token),
    });
  }
}

export const AuthInterceptorProvider = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
