import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ChartConfiguration, ChartData, ChartType, ChartEvent } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import DatalabelsPlugin from "chartjs-plugin-datalabels";

@Component({
  selector: "app-dashboards-pie-chart",
  templateUrl: "./pie-chart.component.html",
  styleUrls: ["./pie-chart.component.scss"],
})
export class PieChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  @Input() labels: any[] = [
    ["Download", "Sales"],
    ["In", "Store", "Sales"],
    "Mail Sales",
  ];
  @Input() dataset: any[] = [
    {
      data: [300, 500, 100],
    },
  ];

  public chartOptions: ChartConfiguration["options"] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      datalabels: {
        formatter: (value, ctx) => {
          return '';
          // if (ctx?.chart?.data?.labels) {
          //   return ctx?.chart?.data?.labels[ctx.dataIndex];
          // }
        },
      },
    },
  };
  public chartData: ChartData<"pie", number[], string | string[]> = {
    labels: this.labels,
    datasets: this.dataset,
  };
  public chartType: ChartType = "pie";
  //public pieChartPlugins = [DatalabelsPlugin];

  constructor() {}

  ngOnInit(): void {
    this.chartData = {
      labels: this.labels,
      datasets: this.dataset,
    };
    //this.chart.update();
  }

  // events
  public chartClicked({
    event,
    active,
  }: {
    event: ChartEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event: ChartEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }
}
