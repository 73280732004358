/* eslint-disable @typescript-eslint/naming-convention */
import { Routes, RouterModule } from "@angular/router";

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: "dashboards",
    loadChildren: () =>
      import("../../dashboards/dashboards.module").then(
        (m) => m.DashboardsModule
      ),
  },
  {
    path: "companies",
    loadChildren: () =>
      import("../../companies/companies.module").then((m) => m.CompaniesModule),
  },
  {
    path: "data-imports",
    loadChildren: () =>
      import("../../data-imports/data-imports.module").then(
        (m) => m.DataImportsModule
      ),
  },
  {
    path: "preferences",
    loadChildren: () =>
      import("../../preferences/preferences.module").then(
        (m) => m.PreferencesModule
      ),
  },
  {
    path: "tgs-categories",
    loadChildren: () =>
      import("../../tgs-categories/tgs-categories.module").then(
        (m) => m.TgsCategoriesModule
      ),
  },
  {
    path: "groups",
    loadChildren: () =>
      import("../../groups/groups.module").then(
        (m) => m.GroupsModule
      ),
  },
  {
    path: "holidays",
    loadChildren: () =>
      import("../../holidays/holidays.module").then((m) => m.HolidaysModule),
  },
  {
    path: "shifts",
    loadChildren: () =>
      import("../../shifts/shifts.module").then((m) => m.ShiftsModule),
  },
  {
    path: "presences",
    loadChildren: () =>
      import("../../presences/presences.module").then((m) => m.PresencesModule),
  },
  {
    path: "calendar",
    loadChildren: () =>
      import("../../calendar/calendar.module").then(
        (m) => m.MyCalendarModule
      ),
  },
  {
    path: "calendar",
    loadChildren: () =>
      import("../../calendar/calendar.module").then((m) => m.MyCalendarModule),
  },
  {
    path: "employees",
    loadChildren: () =>
      import("../../employees/employees.module").then((m) => m.EmployeesModule),
  },
  {
    path: "bonuses",
    loadChildren: () =>
      import("../../bonuses/bonuses.module").then((m) => m.BonusesModule),
  },
  {
    path: "bonus-maluses",
    loadChildren: () =>
      import("../../bonus-maluses/bonus-maluses.module").then(
        (m) => m.BonusMalusesModule
      ),
  },
  {
    path: "employee-costs",
    loadChildren: () =>
      import("../../employee-costs/employee-costs.module").then(
        (m) => m.EmployeeCostsModule
      ),
  },
  {
    path: "paychecks",
    loadChildren: () =>
      import("../../paychecks/paychecks.module").then((m) => m.PaychecksModule),
  },
  {
    path: "resignations",
    loadChildren: () =>
      import("../../resignations/resignations.module").then(
        (m) => m.ResignationsModule
      ),
  },
  {
    path: "resignation",
    loadChildren: () =>
      import("../../resignation/resignation.module").then(
        (m) => m.ResignationModule
      ),
  },
  {
    path: "candidates",
    loadChildren: () =>
      import("../../candidates/candidates.module").then(
        (m) => m.CandidatesModule
      ),
  },
  {
    path: "jobs",
    loadChildren: () =>
      import("../../jobs/jobs.module").then((m) => m.JobsModule),
  },
  {
    path: "closings",
    loadChildren: () =>
      import("../../closings/closings.module").then((m) => m.ClosingsModule),
  },
  {
    path: "closing-fields",
    loadChildren: () =>
      import("../../closing-fields/closing-fields.module").then(
        (m) => m.ClosingFieldsModule
      ),
  },
  {
    path: "bordero",
    loadChildren: () =>
      import("../../bordero/bordero.module").then((m) => m.BorderoModule),
  },
  {
    path: "comunications",
    loadChildren: () =>
      import("../../comunications/comunications.module").then(
        (m) => m.ComunicationsModule
      ),
  },
  {
    path: "stamping",
    loadChildren: () =>
      import("../../stamping/stamping.module").then((m) => m.StampingModule),
  },
  {
    path: "stampings",
    loadChildren: () =>
      import("../../zombie/zombie.module").then((m) => m.ZombieModule),
  },
  {
    path: "my-profile",
    loadChildren: () =>
      import("../../my-profile/my-profile.module").then(
        (m) => m.MyProfileModule
      ),
  },
  {
    path: "tasks",
    loadChildren: () =>
      import("../../tasks/tasks.module").then(
        (m) => m.TasksModule
      ),
  },
  {
    path: "task-schedules",
    loadChildren: () =>
      import("../../task-schedules/task-schedules.module").then(
        (m) => m.TaskSchedulesModule
      ),
  },
];
