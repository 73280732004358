import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";

import { FilterPipe } from './filter.pipe';
import { SearchPipe } from './search.pipe';
import { ShortNamePipe } from './short-name.pipe';
import { SlugifyPipe } from './slugify.pipe';
import { DateAsAgoPipe } from './date-as-ago.pipe';
import { LocalizedDatePipe } from './localized-date.pipe';
import { CounterPipe } from './counter.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
  declarations:[FilterPipe, SearchPipe, ShortNamePipe, SlugifyPipe, DateAsAgoPipe, LocalizedDatePipe, TruncatePipe, CounterPipe],
  imports:[CommonModule],
  exports:[FilterPipe, SearchPipe, ShortNamePipe, SlugifyPipe, DateAsAgoPipe, LocalizedDatePipe, TruncatePipe, CounterPipe]
})

export class PipeModule{}
