/* eslint-disable @typescript-eslint/member-ordering */
import { HttpParams } from "@angular/common/http";
import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ShiftService } from "app/shared/api/shift.service";
import { Shift } from "app/shared/models/shift";
import { compareShiftTimesValidator } from "app/shared/validators/compare-shift-times.validator";
import { ColorPickerService } from "ngx-color-picker";

@Component({
  selector: "app-modal-shift-times",
  templateUrl: "./modal-shift-times.component.html",
  styleUrls: ["./modal-shift-times.component.scss",
  "../../../assets/sass/libs/datatables.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ModalShiftTimesComponent implements OnInit {
  addForm: UntypedFormGroup;
  editForm: UntypedFormGroup;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: "",
    orderDir: "",
    name: null,
    start_time: null,
    end_time: null,
    color: null,
  };
  filterForm: FormGroup;
  columns = [
    { name: "ID", prop: "id" },
    { name: "Name", prop: "name" },
    { name: "Start Time", prop: "start_time" },
    { name: "End Time", prop: "end_time" },
    { name: "Color", prop: "color" },
  ];
  rows: Shift[];

  color = '#ffffff';

  public editing = {};
  submitted = false;

  constructor(
    private translate: TranslateService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private colorPickerService: ColorPickerService,
    private shiftService: ShiftService
  ) {}

  get f() {
    return this.addForm.controls;
  }

  ngOnInit(): void {
    this.pageCallback({ offset: 0 });
    this.addForm = this.formBuilder.group({
      start_time: [null, [Validators.required]],
      end_time: [null, [Validators.required]],
      color: [null, [Validators.required, Validators.pattern('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$')]],
    },
    {
      validator: compareShiftTimesValidator("start_time", "end_time", 12),
    });
    this.editForm = this.formBuilder.group({
      start_time: [null, [Validators.required]],
      end_time: [null, [Validators.required]],
      color: [null, [Validators.required, Validators.pattern('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$')]],
    },
    {
      validator: compareShiftTimesValidator("start_time", "end_time", 12),
    });
  }

  close() {
    this.passEntry.emit();
    this.activeModal.close();
  }
  /**
   * Called whenever the user changes filter form
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  onFilterFormSubmit(): void {
    this.page.name = this.filterForm.get("name").value;
    this.page.start_time = this.filterForm.get("start_time").value;
    this.page.end_time = this.filterForm.get("end_time").value;
    this.page.color = this.filterForm.get("color").value;
    this.reloadTable();
  }

  /**
   * Called whenever the user reset filter form
   */
  onFilterFormReset(): void {
    this.page.name = null;
    this.page.start_time = null;
    this.page.end_time = null;
    this.page.color = null;
    this.reloadTable();
  }

  /**
   * Called whenever the user changes page
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  pageCallback(pageInfo: {
    count?: number;
    pageSize?: number;
    limit?: number;
    offset?: number;
  }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }

  /**
   * Called whenever the user changes the sort order
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  sortCallback(sortInfo: {
    sorts: { dir: string; prop: string }[];
    column: {};
    prevValue: string;
    newValue: string;
  }) {
    // there will always be one "sort" object if "sortType" is set to "single"
    this.page.orderDir = sortInfo.sorts[0].dir === "desc" ? "-" : "";
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.reloadTable();
  }

  /**
   * You will render the table once at the beginning in ngOnInit()
   * and then every time the page OR the sort order are changed
   */
  reloadTable() {
    let params = new HttpParams()
      .set("ordering", `${this.page.orderDir}${this.page.orderBy}`)
      .set("offset", `${this.page.offset * this.page.limit}`)
      .set("limit", `${this.page.limit}`);
    // dynamic filters
    if (typeof this.page.start_time != "undefined" && this.page.start_time) {
      params = params.append("start_time", `${this.page.start_time}`);
    }
    if (typeof this.page.end_time != "undefined" && this.page.end_time) {
      params = params.append("end_time", `${this.page.end_time}`);
    }
    if (typeof this.page.color != "undefined" && this.page.color) {
      params = params.append("color", `${this.page.color}`);
    }
    // console.log(params);
    this.shiftService.find({ params }).subscribe(
      (data) => {
        this.page.count = data["count"];
        this.rows = data["results"];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    if (this.addForm.invalid) {
      return;
    }

    //console.log(this.addForm.value);
    this.shiftService.create(this.addForm.value).subscribe(
      (data) => {
        //console.log(data);
        this.submitted = false;
        this.addForm.reset();
        this.color = '#ffffff';
        this.reloadTable();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * inlineEditingUpdate
   *
   * @param event
   * @param cell
   * @param rowIndex
   */
  inlineEditingUpdate(event, cell, rowIndex) {
    console.log(this.rows[rowIndex][cell]);
    console.log(event.target.value);
    if (this.rows[rowIndex][cell] !== event.target.value) {
      this.rows[rowIndex][cell] = event.target.value;
      this.rows = [...this.rows];
      this.shiftService
        .update(this.rows[rowIndex]["id"], {
          start_time: this.rows[rowIndex]["start_time"],
          end_time: this.rows[rowIndex]["end_time"],
          color: this.rows[rowIndex]["color"],
        })
        .subscribe(
          (data) => {
            //console.log(data);
            this.editing[rowIndex + "-start_time"] = false;
            this.editing[rowIndex + "-end_time"] = false;
            this.editing[rowIndex + "-color"] = false;
            this.reloadTable();
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
  
  onInlineEditingUpdate() {
    
  }


  onEditRow(rowIndex, event) {
    // console.log(this.rows[rowIndex]);
    // this.editForm.patchValue({
    //   start_time: this.rows[rowIndex]['start_time'],
    //   end_time: this.rows[rowIndex]['end_time'],
    //   color: this.rows[rowIndex]['color'],
    // });
    this.editing[rowIndex + "-start_time"] = true;
    this.editing[rowIndex + "-end_time"] = true;
    this.editing[rowIndex + "-color"] = true;
    console.log(this.editing);
  }

  onRemoveRow(id) {
    this.shiftService.remove(id).subscribe(
      (data) => {
        //console.log(data);
        this.reloadTable();
      },
      (error) => {
        console.log(error);
      }
    );
  }
  
  public onChangeColor(color: string): void {
    this.color = color;
    this.addForm.patchValue({ color });
    // console.log('Color changed:', color);
  }

}
