export class GridModel<T> {
  PageSize: number;
  TotalElements: number;
  TotalPages: number;
  CurrentPageNumber: number;
  SortBy: string;
  SortDir: string;
  Filters: Object;
  Data: Array<T>;

  constructor(defaultSortBy: string = "id", defaultSortDir: string = "desc") {
    this.PageSize = 10;
    this.TotalElements = 0;
    this.TotalPages = 0;
    this.CurrentPageNumber = 0;
    this.Filters = new Object();
    this.Data = new Array<T>();

    this.SortBy = defaultSortBy;
    this.SortDir = defaultSortDir === "desc" ? "-" : "";
  }
}