import { Component, EventEmitter, OnDestroy } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "formly-field-select2",
  template: `
    <ng-select
      bindValue="{{ to.valueProp || 'value' }}"
      bindLabel="{{ to.labelProp || 'label' }}"
      [items]="to.options | formlySelectOptions : field | async"
      [multiple]="to.multiple || false"
      [closeOnSelect]="!to.multiple || true"
      [clearable]="true"
      [virtualScroll]="true"
      [formControl]="formControl"
      [formlyAttributes]="field"
    >
    </ng-select>
  `,
})
export class FormlyFieldSelect2 extends FieldType {}
