import { FormlyExtension, FormlyFieldConfig } from "@ngx-formly/core";
import { TranslateService } from "@ngx-translate/core";
import { debounceTime } from "rxjs/operators";

export class TranslateExtension implements FormlyExtension {
  constructor(private translate: TranslateService) {}
  prePopulate(field: FormlyFieldConfig) {
    const props = field.props || {};
    if (!props.translate || props._translated) {
      return;
    }

    props._translated = true;
    field.expressions = {
      ...(field.expressions || {}),
      "props.label": props.label
      ? this.translate.stream(props.label).pipe(debounceTime(100))
      : null,
      "props.placeholder": props.placeholder
        ? this.translate.stream(props.placeholder).pipe(debounceTime(100))
        : null,
      "props.description": props.description
        ? this.translate.stream(props.description).pipe(debounceTime(100))
        : null,
    };
  }
}

export function registerTranslateExtension(translate: TranslateService) {
  return {
    validationMessages: [
      {
        name: "required",
        message() {
          return translate.stream("FORM.VALIDATION.REQUIRED");
        },
      },
      {
        name: "email",
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream("FORM.VALIDATION.EMAIL");
        },
      },
      {
        name: "url",
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream("FORM.VALIDATION.URL");
        },
      },
      {
        name: "ip",
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream("FORM.VALIDATION.IP");
        },
      },
      {
        name: "minLength",
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream("FORM.VALIDATION.MIN_LENGTH", {
            value: field.templateOptions.minLength,
          });
        },
      },
      {
        name: "maxLength",
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream("FORM.VALIDATION.MAX_LENGTH", {
            value: field.templateOptions.maxLength,
          });
        },
      },
      {
        name: "min",
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream("FORM.VALIDATION.MIN", {
            value: field.templateOptions.min,
          });
        },
      },
      {
        name: "max",
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream("FORM.VALIDATION.MAX", {
            value: field.templateOptions.max,
          });
        },
      },
    ],
    extensions: [
      {
        name: "translate",
        extension: new TranslateExtension(translate),
      },
    ],
  };
}
