<div style="display: block">
  <canvas
    baseChart
    class="chart"
    [data]="chartData"
    [options]="chartOptions"
    [type]="chartType"
  >
  </canvas>
</div>
