<div class="modal-header">
  <h4 class="modal-title">{{ 'Manage Shift Times'| translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="needs-validation" [formGroup]="addForm" (ngSubmit)="onSubmit()">
    <div class="{{submitted ? 'mb-4' : '' }} form-group">
      <label for="start_time">{{ 'Start Time'| translate }}</label>
      <div class="input-group">
        <input type="time" class="form-control" formControlName="start_time"
          [ngClass]="{ 'is-invalid': submitted && f.start_time.invalid, 'is-valid': submitted && !f.start_time.invalid }">
        <div *ngIf="submitted && !f.start_time.invalid" class="valid-tooltip">
          <i class="ft-check-circle align-middle"></i> {{ 'Looks Good'| translate }}
        </div>
        <div *ngIf="submitted && f.start_time.errors?.required" class="invalid-tooltip">
          <i class="ft-alert-circle align-middle"></i> {{ 'Required' | translate }}
        </div>
        <div *ngIf="submitted && f.start_time.errors?.equals" class="invalid-tooltip">
          <i class="ft-alert-circle align-middle"></i> {{ 'Start and End must be different' | translate }}
        </div>
        <div *ngIf="submitted && f.start_time.errors?.startGreaterThanEnd" class="invalid-tooltip">
          <i class="ft-alert-circle align-middle"></i> {{ 'Start Greater Than End' | translate }}
        </div>
        <div *ngIf="submitted && f.start_time.errors?.overTime" class="invalid-tooltip">
          <i class="ft-alert-circle align-middle"></i> {{ 'Max Diff Time is 8' | translate }}
        </div>
      </div>
    </div>
    <div class="form-group mb-2">
      <div class="{{submitted ? 'mb-4' : '' }} form-group">
        <label for="end_time">{{ 'End Time'| translate }}</label>
        <div class="input-group">
          <input type="time" class="form-control" formControlName="end_time"
            [ngClass]="{ 'is-invalid': submitted && f.end_time.invalid, 'is-valid': submitted && !f.end_time.invalid }">
          <div *ngIf="submitted && !f.end_time.invalid" class="valid-tooltip">
            <i class="ft-check-circle align-middle"></i> {{ 'Looks Good'| translate }}
          </div>
          <div *ngIf="submitted && f.end_time.errors?.required" class="invalid-tooltip">
            <i class="ft-alert-circle align-middle"></i> {{ 'Required' | translate }}
          </div>
          <div *ngIf="submitted && f.end_time.errors?.equals" class="invalid-tooltip">
            <i class="ft-alert-circle align-middle"></i> {{ 'Start and End must be different' | translate }}
          </div>
          <div *ngIf="submitted && f.end_time.errors?.startGreaterThanEnd" class="invalid-tooltip">
            <i class="ft-alert-circle align-middle"></i> {{ 'Start Greater Than End' | translate }}
          </div>
          <div *ngIf="submitted && f.end_time.errors?.overTime" class="invalid-tooltip">
            <i class="ft-alert-circle align-middle"></i> {{ 'Max Diff Time is 8' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="{{submitted ? 'mb-4' : '' }} form-group">
      <label for="color">{{ 'Color'| translate }}</label>
      <div class="input-group">
        <input [(colorPicker)]="color" [style.background]="color" class="form-control" formControlName="color"
          (colorPickerChange)="onChangeColor($event)" type="text" [cpPosition]="'bottom'"
          [ngClass]="{ 'is-invalid': submitted && f.color.invalid, 'is-valid': submitted && !f.color.invalid }" />
        <div *ngIf="submitted && !f.color.invalid" class="valid-tooltip"><i class="ft-check-circle align-middle"></i>
          {{ 'Looks Good'| translate }}</div>
        <div *ngIf="submitted && f.color.errors?.required" class="invalid-tooltip"><i
            class="ft-alert-circle align-middle"></i> {{ 'Required' | translate }}</div>
        <div *ngIf="submitted && (!f.color.errors?.required && f.color.errors?.pattern)" class="invalid-tooltip">
          {{'Invalid Color Pattern'| translate }}</div>
      </div>
    </div>
    <button type="submit" class="btn btn-primary">{{ 'Save'| translate }}</button>
  </form>
  <hr />
  <ngx-datatable class="bootstrap core-bootstrap" [rows]="rows" [columns]="columns" [columnMode]="'force'"
    [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [externalPaging]="true" [externalSorting]="true"
    [count]="page.count" [offset]="page.offset" [limit]="page.limit" [sortType]="'single'" (page)="pageCallback($event)"
    (sort)="sortCallback($event)"
    [messages]="{emptyMessage: 'No data to display' | translate, totalMessage: 'Total' | translate, selectedMessage: false}"
    [scrollbarH]="true">
    <ngx-datatable-column [name]="'ID'| translate" prop="id" [width]="50"></ngx-datatable-column>
    <ngx-datatable-column [name]="'Start Time'| translate" prop="start_time" [width]="150">
      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
        <div *ngIf="!editing[rowIndex + '-start_time']">
          {{ value }}
        </div>
        <input autofocus class="form-control form-control-sm"
          (blur)="inlineEditingUpdate($event, 'start_time', rowIndex)" *ngIf="editing[rowIndex + '-start_time']"
          type="time" [value]="value" />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'End Time'| translate" prop="end_time" [width]="150">
      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
        <div *ngIf="!editing[rowIndex + '-end_time']">
          {{ value }}
        </div>
        <input autofocus class="form-control form-control-sm" (blur)="inlineEditingUpdate($event, 'end_time', rowIndex)"
          *ngIf="editing[rowIndex + '-end_time']" type="time" [value]="value" />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'Color'| translate" prop="color" [width]="150">
      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
        <div *ngIf="!editing[rowIndex + '-color']">
          {{ value }}
        </div>
        <input autofocus class="form-control form-control-sm" (blur)="inlineEditingUpdate($event, 'color', rowIndex)"
          *ngIf="editing[rowIndex + '-color']" type="string" [value]="value" />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'Actions'| translate" prop="id" [width]="150" [sortable]="false"
      style="overflow:hidden">
      <ng-template let-rowIndex="rowIndex" let-id="value" ngx-datatable-cell-template>
        <i class="ft-save text-primary cursor-pointer mr-2" container="body" placement="top"
          ngbTooltip="{{ 'Save'| translate }}" (click)="inlineEditingUpdate($event, 'start_time', rowIndex)"
          *ngIf="editing[rowIndex + '-start_time']"></i>
        <i class="ft-edit text-primary cursor-pointer mr-2" container="body" placement="top"
          ngbTooltip="{{ 'Edit'| translate }}" (click)="onEditRow(rowIndex, $event)"
          *ngIf="!editing[rowIndex + '-start_time']"></i>
        <i class="ft-trash-2 text-primary cursor-pointer" container="body" placement="top"
          ngbTooltip="{{ 'Delete'| translate }}" (click)="onRemoveRow(id)" *ngIf="!editing[rowIndex + '-start_time']"></i>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">{{ 'Close'| translate
    }}</button>
</div>