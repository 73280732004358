/* eslint-disable @typescript-eslint/member-ordering */
import { HttpParams } from "@angular/common/http";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ShiftJobService } from "app/shared/api/shiftjob.service";
import { ShiftJob } from "app/shared/models/shiftjob";

@Component({
  selector: "app-modal-shift-jobs",
  templateUrl: "./modal-shift-jobs.component.html",
  styleUrls: ["./modal-shift-jobs.component.scss"],
})
export class ModalShiftJobsComponent implements OnInit {
  addForm: UntypedFormGroup;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: "",
    orderDir: "",
    name: null,
  };
  filterForm: FormGroup;
  columns = [
    { name: "ID", prop: "id" },
    { name: "Name", prop: "name" },
    { name: "Description", prop: "description" },
  ];
  rows: ShiftJob[];

  public editing = {};
  submitted = false;

  constructor(
    private translate: TranslateService,
    public activeModal: NgbActiveModal,
    private shiftJobService: ShiftJobService
  ) {}

  get f() {
    return this.addForm.controls;
  }

  ngOnInit(): void {
    this.pageCallback({ offset: 0 });
    this.addForm = new UntypedFormGroup({
      name: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(200),
      ]),
      description: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(200),
      ]),
    });
  }

  close() {
    this.passEntry.emit();
    this.activeModal.close();
  }
  /**
   * Called whenever the user changes filter form
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  onFilterFormSubmit(): void {
    this.page.name = this.filterForm.get("name").value;
    this.reloadTable();
  }

  /**
   * Called whenever the user reset filter form
   */
  onFilterFormReset(): void {
    this.page.name = null;
    this.reloadTable();
  }

  /**
   * Called whenever the user changes page
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  pageCallback(pageInfo: {
    count?: number;
    pageSize?: number;
    limit?: number;
    offset?: number;
  }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }

  /**
   * Called whenever the user changes the sort order
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  sortCallback(sortInfo: {
    sorts: { dir: string; prop: string }[];
    column: {};
    prevValue: string;
    newValue: string;
  }) {
    // there will always be one "sort" object if "sortType" is set to "single"
    this.page.orderDir = sortInfo.sorts[0].dir === "desc" ? "-" : "";
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.reloadTable();
  }

  /**
   * You will render the table once at the beginning in ngOnInit()
   * and then every time the page OR the sort order are changed
   */
  reloadTable() {
    let params = new HttpParams()
      .set("ordering", `${this.page.orderDir}${this.page.orderBy}`)
      .set("offset", `${this.page.offset * this.page.limit}`)
      .set("limit", `${this.page.limit}`);
    // dynamic filters
    if (typeof this.page.name != "undefined" && this.page.name) {
      params = params.append("name", `${this.page.name}`);
    }
    // console.log(params);
    this.shiftJobService.find({ params }).subscribe(
      (data) => {
        this.page.count = data["count"];
        this.rows = data["results"];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    if (this.addForm.invalid) {
      return;
    }
    //console.log(this.addForm.value);
    this.shiftJobService.create(this.addForm.value).subscribe(
      (data) => {
        //console.log(data);
        this.submitted = false;
        this.addForm.reset();
        this.reloadTable();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * inlineEditingUpdate
   *
   * @param event
   * @param cell
   * @param rowIndex
   */
  inlineEditingUpdate(event, cell, rowIndex) {
    this.editing[rowIndex + "-" + cell] = false;
    // console.log(this.rows[rowIndex][cell]);
    // console.log(event.target.value);
    if (this.rows[rowIndex][cell] !== event.target.value) {
      this.rows[rowIndex][cell] = event.target.value;
      this.rows = [...this.rows];
      this.shiftJobService
        .update(this.rows[rowIndex]["id"], {
          name: this.rows[rowIndex]["name"],
          description: this.rows[rowIndex]["description"],
        })
        .subscribe(
          (data) => {
            //console.log(data);
            this.reloadTable();
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  onEditRow(rowIndex, event) {
    this.editing[rowIndex + "-name"] = true;
    this.editing[rowIndex + "-description"] = true;
  }

  onRemoveRow(id) {
    this.shiftJobService.remove(id).subscribe(
      (data) => {
        //console.log(data);
        this.reloadTable();
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
