import { Component } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";

@Component({
  selector: "formly-field-photo",
  template: `
    <div class="form-group" [ngClass]="{ 'd-none': to.hidden }">
      <label>
        {{ to.label }}
        <span *ngIf="to.required">*</span>
      </label>
      <div class="input-group">
        <div class="media">
          <img
            [src]="sanitized(formControl.value)"
            alt="{{ to.label }}"
            class="rounded mr-3"
            height="64"
            width="64"
          />
          <div class="media-body">
            <div
              class="col-12 d-flex flex-sm-row flex-column justify-content-start px-0 mb-sm-2"
            >
              <label
                *ngIf="formControl.value"
                class="btn btn-sm bg-light-primary mb-sm-0"
                [for]="to.key"
              >
                {{ "Change" | translate }}</label
              >
              <label
                *ngIf="!formControl.value"
                class="btn btn-sm bg-light-primary mb-sm-0"
                [for]="to.key"
              >
                {{ "Select" | translate }}</label
              >
              <input
                type="file"
                [id]="to.key"
                style="display: none"
                [formControl]="formControl"
                (change)="onShowPreview($event)"
                [attr.disabled]="to.disabled ? '' : null"
                accept="image/png, image/jpeg"
              />
              <button
                class="btn btn-sm bg-light-info ml-sm-2"
                *ngIf="checkUrl(formControl.value)"
                (click)="onNavigate(formControl.value)"
              >
                {{ "Open" | translate }}
              </button>
              <button
                class="btn btn-sm bg-light-secondary ml-sm-2"
                [disabled]="to.disabled"
                (click)="onReset()"
              >
                {{ "Reset" | translate }}
              </button>
            </div>
            <p class="text-muted mb-0 mt-1 mt-sm-0">
              <small>{{ "Image Upload Size Description" | translate }}</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class FormlyFieldPhoto extends FieldType<FieldTypeConfig> {
  fieldSrc: any;
  invalidFieldSrc = false;

  constructor(public sanitizer: DomSanitizer) {
    super();
  }

  sanitized(value) {
    if (value) {
      if (value instanceof File) return this.fieldSrc;
      else return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    } else {
      return "assets/img/no-image.png";
    }
  }

  onShowPreview(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.formControl.patchValue(file);
    this.invalidFieldSrc = false;
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.fieldSrc = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  onReset() {
    this.fieldSrc = null;
    this.invalidFieldSrc = true;
    this.formControl.reset();
  }

  checkUrl(url) {
    try {
      let value = new URL(url);
    } catch (_) {
      return false;
    }
    return true;
  }

  onNavigate(url){
    window.open(url, '_blank');
  }
}
