import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PdfViewerComponent as Ng2PdfViewer } from "ng2-pdf-viewer";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: ["./pdf-viewer.component.scss"],
})
export class PdfViewerComponent implements OnInit {
  @Input() src: string;
  @Input() onReadFile;
  @Input() showNav: boolean = true;

  renderText = true;
  originalSize = false;
  fitToPage = false;
  stickToPage = true;
  showAll = true;
  autoresize = false;
  showBorders = true;
  renderTextModes = [0, 1, 2];
  renderTextMode = 1;
  rotation = 0;
  zoom = 1;
  zoomScale = "page-width";
  zoomScales = ["page-width", "page-fit", "page-height"];
  totalPages: number;
  page: number = 1;
  isLoaded: boolean = false;

  @ViewChild(Ng2PdfViewer) private pdfComponent: Ng2PdfViewer;

  constructor(
    private translateService: TranslateService,
    private spinnerService: NgxSpinnerService
  ) {
    this.spinnerService.show();
  }

  ngOnInit(): void {
  }

  getPdfSrc() {
    console.log("getPdfSrc", this.src);
    if (this.src) {
    return "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
    // return {
    //   url: this.src,
    //   withCredentials: true,
    // };
  }
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }

  zoomIn() {
    this.zoom += 0.05;
  }

  zoomOut() {
    if (this.zoom > 0.05) this.zoom -= 0.05;
  }

  rotateDoc() {
    this.rotation += 90;
  }

  afterLoadComplete(event) {
    console.log("afterLoadComplete", event);
    // Setting total number of pages
    this.totalPages = event._pdfInfo.numPages;
    this.isLoaded = true;
    this.spinnerService.hide();
  }

  pageRendered(event) {
    console.log("pageRendered", event);
  }

  textLayerRendered(event) {
    console.log("textLayerRendered", event);
  }

  onError(event) {
    console.error("onError", event);
  }

  onProgress(event) {
    console.log("onProgress", event);
  }
}
