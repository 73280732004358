import { Component } from "@angular/core";
import {
  FieldType,
} from "@ngx-formly/core";

@Component({
  selector: "formly-field-nav",
  template: `
    <div class="form-group">
      <label [for]="field.key">
        {{ to.label }}
        <span *ngIf="to.required">*</span>
      </label>
      <div class="row">
        <div class="col-md-4 mt-3">
            <!-- Nav tabs -->
            <ul class="nav flex-column nav-pills" [id]="field.key" role="tablist">
                <li class="nav-item" *ngFor="let group of props.options | keyvalue">
                    <a class="nav-link" href="javascript:;" [ngClass]="{active: props.activeTab === group.key}"
                        (click)="setActiveTab(group.key)" id="{{ group.key }}-tab" data-toggle="tab" role="tab"
                        aria-controls="{{ group.key }}" aria-selected="true">
                        <i class="fa-solid fa-caret-right mr-1 align-middle"></i>
                        <span class="align-middle">{{ group.key }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="col-md-8">
            <!-- Tab panes -->
            <div class="card">
                <div class="card-content">
                    <div class="card-body">
                        <div class="tab-content">
                            <ng-container *ngFor="let counter of [props.options | counterPipe]">
                                {{ counter.reset() }}
                                <ng-container *ngFor="let group of props.options | keyvalue">
                                    <div class="tab-pane" [ngClass]="{active: props.activeTab === group.key}"
                                        id="{{ group.key }}" role="tabpanel" aria-labelledby="{{ group.key }}-tab">
                                        <div class="container mb-2" *ngFor="let subGroup of group.value | keyvalue">
                                            <h5>{{ subGroup.key }}</h5>
                                            <ul class="list-group">
                                                <li class="list-group-item" *ngFor="let value of subGroup.value">
                                                    <div class="checkbox">
                                                        <input type="checkbox" [disabled]="to.disabled" [id]="counter.val()" [formlyAttributes]="field"
                                                          [value]="value.id"
                                                          [checked]="isChecked(value)" 
                                                          (change)="onChange(value.id, $any($event.target).checked)">
                                                        <label [for]="counter.val()">
                                                            {{ value.name | translate }}
                                                        </label>
                                                    </div>
                                                    {{ counter.inc() }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  `,
})
export class FormlyFieldNav extends FieldType {

  setActiveTab(tab) {
    this.props.activeTab = tab;
  }

  onChange(value: any, checked: boolean) {
    this.formControl.markAsDirty();
    if (this.props.type === 'array') {
      this.formControl.patchValue(
        checked
          ? [...(this.formControl.value || []), value]
          : [...(this.formControl.value || [])].filter((o) => o !== value),
      );
    } else {
      this.formControl.patchValue({ ...this.formControl.value, [value]: checked });
    }
    this.formControl.markAsTouched();
  }

  isChecked(option: any) {
    const value = this.formControl.value;

    return value && (this.props.type === 'array' ? value.indexOf(option.id) !== -1 : value[option.id]);
  }
}
