<div style="display: block">
  <canvas
    baseChart
    class="chart"
    [data]="chartData"
    [options]="chartOptions"
    [type]="chartType"
    (chartHover)="chartHovered($event)"
    (chartClick)="chartClicked($event)"
  ></canvas>
</div>
