import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { first, map, tap } from "rxjs/operators";
import { SessionStorageService } from "../services/session-storage.service";
import { AbstractService } from "./abstract.service";
import { HttpClient } from "@angular/common/http";
import { Operator } from "../models/operator";
import { CommonUtilsService } from "../services/common-utils.service";
import { NgxPermissionsService, NgxRolesService } from "ngx-permissions";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class AuthService extends AbstractService {
  constructor(
    public httpClient: HttpClient,
    public encryptStorage: SessionStorageService,
    private cookieService: CookieService,
    private commonUtils: CommonUtilsService,
    private router: Router,
    private rolesService: NgxRolesService
  ) {
    super(httpClient, encryptStorage);
  }

  register(email: string, password: string) {
    // TODO
  }

  login(email: string, password: string): Observable<any> {
    return this.post<any>(
      `/rest-auth/login/`,
      { email, password },
      {},
      false
    ).pipe(
      map((res) => {
        this.encryptStorage.setItem("User", res.user);
        this.encryptStorage.setItem("AccessToken", res.access_token);
        this.encryptStorage.setItem("RefreshToken", res.refresh_token);
        this.refreshRolePermissions();
        return res;
      })
    );
  }

  clearData() {
    this.encryptStorage.clearData();
    this.rolesService.flushRolesAndPermissions();
    this.cookieService.deleteAll("/");
  }

  logout() {
    return this.post<any>(`/rest-auth/logout/`, {}).pipe(
      map((res) => {
        this.clearData();
        return res;
      })
    );
  }

  resetPassword(data) {
    return this.post(`/rest-auth/password/reset/`, data, {}, false);
  }

  confirmResetPassword(data) {
    return this.post(`/rest-auth/password/reset/confirm/`, data, {}, false);
  }

  changePassword(data) {
    return this.post(`/rest-auth/password/change/`, data);
  }

  confirmEmail(data) {
    return this.post(`/rest-auth/verify-email/`, data, {}, false);
  }

  isLoggedIn() {
    return this.encryptStorage.getItem("User") !== (null || undefined);
  }

  setUser(user) {
    this.encryptStorage.setItem("User", user);
  }

  refreshUser() {
    const promise = new Promise<void>((resolve, reject) => {
      this.get<any>(`/rest-auth/user/`).subscribe(
        (data) => {
          console.log(data);
          this.encryptStorage.setItem("User", data);
          resolve();
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    });
    return promise;
  }

  getUser(camelize = true) {
    const j = this.encryptStorage.getItem("User") || undefined;
    if (j) {
      let user;
      if (camelize) {
        user = this.commonUtils.keysToCamel(JSON.parse(j)) as Operator;
      } else {
        user = JSON.parse(j);
      }
      if (user) {
        return user;
      }
    }
    return null;
  }

  getAvatar() {
    const user = this.getUser() || undefined;
    if (user) {
      this.get<any>(user.avatar).subscribe(
        (data) => {
          console.log(data);
          return data;
        },
        (error) => {
          console.log(error);
        }
      );
    }
    return null;
  }

  getAccessToken() {
    const token = this.encryptStorage.getItem("AccessToken");
    if (token) {
      return token;
    }

    return null;
  }

  saveAccessToken(token) {
    this.encryptStorage.setItem("AccessToken", token);
  }

  getRefreshToken() {
    const token = this.encryptStorage.getItem("RefreshToken");
    if (token) {
      return token;
    }

    return null;
  }

  saveRefreshToken(token) {
    this.encryptStorage.setItem("RefreshToken", token);
  }

  verifyToken(token) {
    return this.post(
      `/rest-auth/token/verify/`,
      { token: token },
      { withCredentials: true }
    ).pipe(
      map((res) => {
        //console.log('verify access token');
        //console.log(res);
        if (res) {
          return true;
        }
        return false;
      })
    );
  }

  refreshToken(token) {
    return this.post(`/rest-auth/token/refresh/`, { refresh: token }).pipe(
      tap((res) => {
        console.log("refreshed access token", res);
        this.saveAccessToken(res["access"]);
        this.refreshRolePermissions();
        return res;
      })
    );
  }

  refreshRolePermissions() {
    this.rolesService.flushRolesAndPermissions();
    const user = this.getUser();
    //console.log(user);
    if (user.groups && user.groups.length > 0) {
      user.groups.forEach((group) => {
        let permissions = [];
        permissions.push(group.name);
        group.permissions.forEach((permission) => {
          permissions.push(permission.codename);
        });
        this.rolesService.addRoleWithPermissions(group.name, permissions);
      });
    }
  }

  redirectToDefaultPageByRole(user: Operator) {
    console.log("redirectToDefaultPageByRole");
    if (user.isSuperuser) {
      this.router.navigate(["/dashboards/companies"]);
    } else if (user.isZombie) {
      this.router.navigate(["/stampings"]);
    } else {
      this.router.navigate(["/dashboards/my"]);
    }
  }
}
