import { ConfigOption } from "@ngx-formly/core";
import { UrlValidator, IpAddressValidator, EmailValidator } from "./helpers/validators";
import { FormlyFieldButton } from "./types/field-button/field-button.type";
import { FormlyFieldChips } from "./types/field-chips/field-chips.component";
import { FormlyFieldDatePicker } from "./types/field-datepicker/field-datepicker.component";
import { FormlyFieldFiltersButtons } from "./types/field-filters-buttons/field-filters-buttons.type";
import { FormlyFieldModalInputModal } from "./types/field-modal-input/field-modal-input.component";
import { FormlyFieldNav } from "./types/field-nav/field-nav.component";
import { FormlyFieldOne2Many } from "./types/field-one2many/field-one2many.component";
import { FormlyFieldPassword } from "./types/field-password/field-password.component";
import { FormlyFieldPhoto } from "./types/field-photo/field-photo.component";
import { FormlyFieldRating } from "./types/field-rating/field-rating.component";
import { FormlyFieldCustomSelect } from "./types/field-select/field-select.component";
import { FormlyFieldSelect2 } from "./types/field-select2/field-select2.component";
import { FormlyFieldSignature } from "./types/field-signature/field-signature.component";
import { FormlyFieldSlider } from "./types/field-slider/field-slider.component";
import { FormlyFieldSwitch } from "./types/field-switch/field-switch.component";
import { FormlyFieldTabs } from "./types/field-tabs/field-tabs.component";
import { FormlyFieldWizard } from "./types/field-wizard/field-wizard.component";
import { CardWrapperComponent } from "./wrappers/card-wrapper.component";
import { ModalWrapperComponent } from "./wrappers/modal-wrapper.component";

export const FORM_CONFIG: ConfigOption = {
    validators: [
      { name: 'email', validation: EmailValidator },
      { name: 'url', validation: UrlValidator },
      { name: 'ip', validation: IpAddressValidator },
    ],
    wrappers: [{ name: "card", component: CardWrapperComponent }, { name: "modal", component: ModalWrapperComponent }],
    types: [
      {
        name: "date",
        extends: "input",
        defaultOptions: {
          props: {
            type: "date",
          },
        },
      },
      {
        name: "time",
        extends: "input",
        defaultOptions: {
          props: {
            type: "time",
          },
        },
      },
      {
        name: "datetime",
        extends: "input",
        defaultOptions: {
          props: {
            type: "datetime-local",
          },
        },
      },
      {
        name: "color",
        extends: "input",
        defaultOptions: {
          props: {
            type: "color",
            addonLeft: {
              class: 'fa-solid fa-palette',
            },
          },
        },
      },
      {
        name: "tel",
        extends: "input",
        defaultOptions: {
          props: {
            type: "tel",
            addonLeft: {
              class: 'fa-solid fa-phone',
            },
          },
        },
      },
      {
        name: "email",
        extends: "input",
        defaultOptions: {
          props: {
            type: "email",
            addonLeft: {
              class: 'fa-solid fa-envelope',
            },
          },
        },
      },
      {
        name: "search",
        extends: "input",
        defaultOptions: {
          props: {
            type: "search",
            addonLeft: {
              class: 'fa-solid fa-magnifying-glass',
            },
          },
        },
      },
      {
        name: "url",
        extends: "input",
        defaultOptions: {
          props: {
            type: "url",
            addonLeft: {
              class: 'fa-solid fa-globe',
            },
          },
        },
      },
      {
        name: "range",
        extends: "input",
        defaultOptions: {
          props: {
            type: "range",
          },
        },
      },
      {
        name: "file",
        extends: "input",
        defaultOptions: {
          props: {
            type: "file",
          },
        },
      },
      // { name: 'file', component: FormlyFieldFile },
      { name: "password", component: FormlyFieldPassword },
      { name: "photo", component: FormlyFieldPhoto },
      { name: "signature", component: FormlyFieldSignature, wrappers: ["form-field"] },
      { name: "chips", component: FormlyFieldChips, extends: "input" },
      { name: "datepicker", component: FormlyFieldDatePicker, wrappers: ["form-field"] },
      { name: "rating", component: FormlyFieldRating, wrappers: ["form-field"] },
      { name: "slider", component: FormlyFieldSlider, wrappers: ["form-field"] },
      { name: "switch", component: FormlyFieldSwitch },
      { name: 'custom-select', component: FormlyFieldCustomSelect, wrappers: ['form-field'] },
      { name: "select2", component: FormlyFieldSelect2, extends: "input" },
      
      { name: "modal-input", component: FormlyFieldModalInputModal },

      { name: "tabs", component: FormlyFieldTabs },
      { name: "nav", component: FormlyFieldNav },
      { name: "wizard", component: FormlyFieldWizard },

      { name: 'one2many', component: FormlyFieldOne2Many },

      {name: 'button', component: FormlyFieldButton },

      {name: 'filters-buttons', component: FormlyFieldFiltersButtons }
    ],
    presets: [],
  };