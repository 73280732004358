import { Component } from "@angular/core";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";

@Component({
  selector: "formly-field-rating",
  template: `
    <div
      class="form-group"
      [class.has-success]="formControl.valid"
      [class.has-danger]="formControl.invalid"
    >
      <ngb-rating
        [formControl]="formControl"
        (hover)="hovered = $event"
        (leave)="hovered = 0"
        [readonly]="readonly"
        [attr.disabled]="disabled"
      >
        <ng-template let-fill="fill">
          <span class="star" [class.filled]="fill === 100">&#9733;</span>
        </ng-template>
      </ngb-rating>
      <div class="form-control-feedback">
        <div *ngIf="formControl.valid">Thanks!</div>
        <div *ngIf="formControl.errors">Please rate us</div>
      </div>
    </div>
  `,
  styleUrls: ["./field-rating.component.scss"],
})
export class FormlyFieldRating extends FieldType<FieldTypeConfig> {
  selected = 0;
  hovered = 0;
  readonly = false;
  disabled = false;
}
