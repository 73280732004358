import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Company } from '../models/company';
import { SessionStorageService } from '../services/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends AbstractService {

  private url = '/companies';

  constructor(httpClient: HttpClient, encryptStorage: SessionStorageService){
    super(httpClient, encryptStorage)
  }

  find(params: any): Observable<Company[]> {
    return this.get(`${this.url}/`, params);
  }

  retrieve(id: any): Observable<Company> {
    return this.get(`${this.url}/${id}/`);
  }

  create(data: any): Observable<any> {
    return this.post(`${this.url}/`, data);
  }

  update(id: any, data: any): Observable<any> {
    return this.put(`${this.url}/${id}/`, data);
  }

  remove(id: any): Observable<any> {
    return this.delete(`${this.url}/${id}/`);
  }

  getTypes(): Observable<any> {
    return this.get(`${this.url}/types/`);
  }

  getDefaultStats(): Observable<any> {
    return this.get(`${this.url}/stats/default_params/`);
  }

  getCompanyStats(id: any, params: any): Observable<any> {
    return this.get(`${this.url}/${id}/stats/`, params);
  }

  /*
  findByTitle(title: any): Observable<Company[]> {
    return this.get(`${this.url}/?title=${title}`);
  }
*/
}