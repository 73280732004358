import { Component } from "@angular/core";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";

@Component({
  selector: "formly-field-switch",
  template: `
    <div class="form-group custom-control-inline mb-1 mb-xl-0">
      <ui-switch
        size="small"
        class="switchery"
        [formControl]="formControl"
      ></ui-switch>
      <label for="switchery" class="pt-1 pl-1">{{ to.label }}</label>
    </div>
  `,
})
export class FormlyFieldSwitch extends FieldType<FieldTypeConfig> {}
