export const ADMIN_LISTITEMS: any[] = [
    { url: '/dashboards/income-statement', name: 'Income Statement', icon: 'fa-solid fa-chart-line' },
    { url: '/dashboards/companies', name: 'Company Statistics', icon: 'fa-solid fa-building' },
    { url: '/dashboards/hr', name: 'Human Resources', icon: 'fa-solid fa-ranking-star' },
    { url: '/dashboards/shifts', name: 'Shifts Statistics', icon: 'fa-solid fa-magnifying-glass-chart' },
    { url: '/dashboards/closings', name: 'Closings Statistics', icon: 'fa-solid fa-chart-pie' },
    { url: '/companies', name: 'Companies', icon: 'fa-solid fa-building' },
    { url: '/data-imports', name: 'Data Imports', icon: 'fa-solid fa-cloud-arrow-up' },
    { url: '/preferences', name: 'Preferences', icon: 'fa-solid fa-gear' },
    { url: '/presences', name: 'Attendance Sheet', icon: 'fa-solid fa-certificate' },
    { url: '/calendar', name: 'Calendar', icon: 'fa-solid fa-calendar' },
    { url: '/holidays', name: 'Holidays', icon: 'fa fa-plane' },
    { url: '/shifts', name: 'Shifts', icon: 'fa-solid fa-calendar-check' },
    { url: '/employees', name: 'Employee List', icon: 'fa-solid fa-address-book' },
    { url: '/bonuses', name: 'Bonus', icon: 'fa-solid fa-coins' },
    { url: '/bonus-maluses', name: 'Bonus Maluses', icon: 'fa fa-gift' },
    { url: '/employee-costs', name: 'Employee Costs', icon: 'fa-solid fa-sack-dollar' },
    { url: '/paychecks', name: 'Paychecks', icon: 'fa-solid fa-envelope-open-text' },
    { url: '/resignations', name: 'Resignations', icon: 'fa-solid fa-box-archive' },
    { url: '/candidates', name: 'Candidates', icon: 'fa-solid fa-id-card' },
    { url: '/jobs', name: 'Jobs', icon: 'fa fa-briefcase' },
    { url: '/closings/new', name: 'New Closing', icon: 'ft-briefcase' },
    { url: '/closings', name: 'Closing List', icon: 'fa fa-calculator' },
    { url: '/closing-fields', name: 'Closing Fields', icon: 'fa fa-th' },
    { url: '/bordero', name: 'Borderò List', icon: 'fa-solid fa-list-alt' },
    { url: '/bordero/providers', name: 'Borderò Provider List', icon: 'fa-solid fa-list-alt' },
    { url: '/bordero/services', name: 'Borderò Service List', icon: 'fa-solid fa-list-alt' },
    { url: '/comunications', name: 'Comunication List', icon: 'ft-bell' },
];

export const EMPLOYEE_LISTITEMS: any[] = [
    { url: '/dashboards/my', name: 'MyDashboard', icon: 'ft-home' },
    { url: '/stamping', name: 'Stamping', icon: 'fa-solid fa-stamp' },
    { url: '/calendar', name: 'Calendar', icon: 'fa-solid fa-calendar' },
    { url: '/holidays', name: 'Holidays', icon: 'fa fa-plane' },
    { url: '/paychecks', name: 'Paychecks', icon: 'fa-solid fa-envelope-open-text' },
    { url: '/resignation', name: 'Resignation', icon: 'fa-solid fa-box-archive' },
    { url: '/closings/new', name: 'New Closing', icon: 'ft-briefcase' },
];

export const ZOMBIE_LISTITEMS: any[] = [
    { url: '/stampings', name: 'Stampings', icon: 'fa-solid fa-stamp' },
];
