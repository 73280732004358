import { Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "formly-field-filters-buttons",
  template: `
      <button
        type="submit"
        class="btn btn-primary btn-block glow"
        (click)="onSubmit($event)"
      >
        <i class="fa-solid fa-filter"></i>
        {{ props.btnSubmitLabel }}
      </button>
      <button
        type="reset"
        class="btn btn-danger btn-block glow"
        (click)="onReset($event)"
      >
        <i class="fa-solid fa-eraser"></i>
        {{ props.btnResetLabel }}
      </button>
  `,
})
export class FormlyFieldFiltersButtons extends FieldType {
  onSubmit($event: Event) {
    if (this.props.onSubmit) {
      this.props.onSubmit($event);
    }
  }

  onReset($event: Event) {
    if (this.props.onReset) {
      this.props.onReset($event);
    }
  }
}
