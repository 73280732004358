import { Component, ViewChild } from "@angular/core";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";
import { SignaturePad } from "angular2-signaturepad";
import { CommonUtilsService } from "app/shared/services/common-utils.service";

@Component({
  selector: "formly-field-signature",
  template: `
    <div class="signature-container">
      <signature-pad
        (onBeginEvent)="drawStart()"
        (onEndEvent)="drawComplete()"
      ></signature-pad>
      <button
        *ngIf="signatureImg"
        type="button"
        class="close"
        aria-label="Close"
        (click)="drawClear()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  `,
  styleUrls: ["./field-signature.component.scss"],
})
export class FormlyFieldSignature extends FieldType<FieldTypeConfig> {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  signatureImg: any;
  signatureFilename = "sign.png";
  signaturePadOptions: Object = {
    minWidth: 0.5,
    canvasWidth: 500,
    canvasHeight: 300,
  };

  constructor(private commonUtils: CommonUtilsService) {
    super();
  }

  drawClear() {
    this.signaturePad.clear();
    this.formControl.setValue(null);
  }
  
  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
    this.signatureImg = this.commonUtils.base64toBlob(
      this.signaturePad.toDataURL().replace(/^data:image\/?[A-z]*;base64,/, ""),
      "image/png"
    );
    this.formControl.setValue(this.signaturePad.toDataURL());
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log("begin drawing");
    // this.signaturePad.clear();
    // this.formControl.setValue(null);
  }
}
