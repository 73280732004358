<div class="modal-header">
  <h4 class="modal-title">{{ 'Manage Shift Jobs'| translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="needs-validation" [formGroup]="addForm" (ngSubmit)="onSubmit()">
    <div class="{{submitted ? 'mb-4' : '' }} form-group">
      <label for="name">{{ 'Name'| translate }}</label>
      <div class="input-group">
        <input type="text" class="form-control" formControlName="name"
          [ngClass]="{ 'is-invalid': submitted && f.name.invalid, 'is-valid': submitted && !f.name.invalid }">
          <div *ngIf="submitted && !f.name.invalid" class="valid-tooltip"><i class="ft-check-circle align-middle"></i> {{ 'Looks Good'| translate }}</div>
          <div *ngIf="submitted && f.name.errors?.required" class="invalid-tooltip"><i class="ft-alert-circle align-middle"></i> {{ 'Required' | translate }}</div>
          <div *ngIf="submitted && (!f.name.errors?.required && f.name.errors?.minlength)" class="invalid-tooltip">{{ 'Minimum 3 Characters required'| translate }}</div>
          <div *ngIf="submitted && (!f.name.errors?.required && f.name.errors?.maxlength)" class="invalid-tooltip">{{ 'Maximum 200 Characters required'| translate }}</div>
      </div>
    </div>
    <div class="{{submitted ? 'mb-4' : '' }} form-group">
      <label for="description">{{ 'Description'| translate }}</label>
      <div class="input-group">
        <input type="text" class="form-control" formControlName="description"
          [ngClass]="{ 'is-invalid': submitted && f.description.invalid, 'is-valid': submitted && !f.description.invalid }">
          <div *ngIf="submitted && !f.description.invalid" class="valid-tooltip"><i class="ft-check-circle align-middle"></i> {{ 'Looks Good'| translate }}</div>
          <div *ngIf="submitted && f.description.errors?.required" class="invalid-tooltip"><i class="ft-alert-circle align-middle"></i> {{ 'Required' | translate }}</div>
          <div *ngIf="submitted && (!f.description.errors?.required && f.description.errors?.minlength)" class="invalid-tooltip">{{ 'Minimum 3 Characters required'| translate }}</div>
          <div *ngIf="submitted && (!f.description.errors?.required && f.description.errors?.maxlength)" class="invalid-tooltip">{{ 'Maximum 200 Characters required'| translate }}</div>
      </div>
    </div>
    <button type="submit" class="btn btn-primary">{{ 'Save'| translate }}</button>
  </form>
  <hr />
  <ngx-datatable class="bootstrap core-bootstrap" [rows]="rows" [columns]="columns" [columnMode]="'force'"
    [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [externalPaging]="true" [externalSorting]="true"
    [count]="page.count" [offset]="page.offset" [limit]="page.limit" [sortType]="'single'" (page)="pageCallback($event)"
    (sort)="sortCallback($event)"
    [messages]="{emptyMessage: 'No data to display' | translate, totalMessage: 'Total' | translate, selectedMessage: false}"
    [scrollbarH]="true">
    <ngx-datatable-column [name]="'ID'| translate" prop="id" [width]="50"></ngx-datatable-column>
    <ngx-datatable-column [name]="'Name'| translate" prop="name" [width]="150">
      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
        <div *ngIf="!editing[rowIndex + '-name']">
          {{ value }}
        </div>
        <input autofocus class="form-control form-control-sm" (blur)="inlineEditingUpdate($event, 'name', rowIndex)"
          *ngIf="editing[rowIndex + '-name']" type="text" [value]="value" />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'Description'| translate" prop="description" [width]="150">
      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
        <div *ngIf="!editing[rowIndex + '-description']">
          {{ value }}
        </div>
        <input autofocus class="form-control form-control-sm"
          (blur)="inlineEditingUpdate($event, 'description', rowIndex)" *ngIf="editing[rowIndex + '-description']"
          type="text" [value]="value" />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'Actions'| translate" prop="id" [width]="150" [sortable]="false"
      style="overflow:hidden">
      <ng-template let-rowIndex="rowIndex" let-id="value" ngx-datatable-cell-template>
        <i class="ft-save text-primary cursor-pointer mr-2" container="body" placement="top"
          ngbTooltip="{{ 'Save'| translate }}" (click)="inlineEditingUpdate($event, 'name', rowIndex)"
          *ngIf="editing[rowIndex + '-name']"></i>
        <i class="ft-edit text-primary cursor-pointer mr-2" container="body" placement="top"
          ngbTooltip="{{ 'Edit'| translate }}" (click)="onEditRow(rowIndex, $event)"
          *ngIf="!editing[rowIndex + '-name']"></i>
        <i class="ft-trash-2 text-primary cursor-pointer" container="body" placement="top"
          ngbTooltip="{{ 'Delete'| translate }}" (click)="onRemoveRow(id)" *ngIf="!editing[rowIndex + '-name']"></i>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">
    {{ 'Close'| translate }}</button>
</div>