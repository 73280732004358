import { Component, ViewEncapsulation } from "@angular/core";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";
import { Observable } from "rxjs";

@Component({
  selector: "formly-field-chips",
  template: `
    <tag-input
      [formControl]="formControl"
      [formlyAttributes]="field"
      [modelAsStrings]="true"
    >
      <tag-input-dropdown *ngIf="to.onAutocompleteObservable" [autocompleteObservable]="onAutocompleteObservable" [minimumTextLength]="0"
        [showDropdownIfEmpty]="true" [focusFirstElement]="true">
      </tag-input-dropdown>
    </tag-input>
  `,
  styleUrls: ["../../../../../../assets/sass/libs/input-tags.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FormlyFieldChips extends FieldType<FieldTypeConfig> {
  
  public onAutocompleteObservable = (text: string): Observable<any> => {
    return this.to.onAutocompleteObservable(text);
  };
}
