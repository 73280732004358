import { Component } from "@angular/core";
import { NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n } from "@ng-bootstrap/ng-bootstrap";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";
import { CustomAdapter } from "./field-datepicker.adapter";
import { CustomDateParserFormatter } from "./field-datepicker.formatter";

@Component({
  selector: "formly-field-datepicker",
  template: `
      <div class="input-group">
        <input
          class="form-control"
          placeholder="yyyy-mm-dd"
          name="datepicker"
          [formControl]="formControl"
          [formlyAttributes]="field"
          ngbDatepicker
          #datepickerInput="ngbDatepicker"
          [class.is-invalid]="showError"
          [attr.aria-invalid]="showError"
          container="body"
        />
        <div class="input-group-append">
          <div class="input-group-text" (click)="datepickerInput.toggle()">
            <i class="fa-solid fa-calendar" style="cursor: pointer;"></i>
          </div>
        </div>
      </div>

    <ng-template #customDay let-date>
      <div class="datepicker-day btn-light">
        {{ date.day }}
      </div>
    </ng-template>
  `,
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    // {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}
  ]
})
export class FormlyFieldDatePicker extends FieldType<FieldTypeConfig> {

}
