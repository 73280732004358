import { Component } from "@angular/core";
import { FieldWrapper } from "@ngx-formly/core";

@Component({
  selector: "formly-wrapper-card",
  template: `
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">{{ to.label }}</h4>
        <h6 class="card-subtitle text-muted mb-0">{{ to.description }}</h6>
      </div>
      <div class="card-content">
        <div class="card-body">
          <ng-container #fieldComponent></ng-container>
        </div>
      </div>
    </div>
  `,
})
export class CardWrapperComponent extends FieldWrapper {}
