import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../services/session-storage.service';
import { ClosingReturn } from '../models/closingReturn';

@Injectable({
  providedIn: 'root'
})
export class ClosingReturnService extends AbstractService {

  private url = '/closings';

  constructor(httpClient: HttpClient, encryptStorage: SessionStorageService){
    super(httpClient, encryptStorage)
  }

  find(closingId: number, params: any): Observable<ClosingReturn[]> {
    return this.get(`${this.url}/${closingId}/returns/`, params);
  }

  retrieve(closingId: number, id: number): Observable<ClosingReturn> {
    return this.get(`${this.url}/${closingId}/returns/${id}/`);
  }

  create(closingId: number, data: any): Observable<any> {
    return this.post(`${this.url}/${closingId}/returns/`, data);
  }

  update(closingId: number, id: number, data: any): Observable<any> {
    return this.put(`${this.url}/${closingId}/returns/${id}/`, data);
  }

  remove(closingId: number, id: number): Observable<any> {
    return this.delete(`${this.url}/${closingId}/returns/${id}/`);
  }

  sendReminder(closingId: number, id: number): Observable<ClosingReturn> {
    return this.get(`${this.url}/${closingId}/returns/${id}/resend/`);
  }

  /*
  findByTitle(title: any): Observable<ClosingReturn[]> {
    return this.get(`${this.url}/?title=${title}`);
  }
*/
}