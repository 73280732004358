import {
  Component,
  Injectable,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ModalConfig } from "./modal.config";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";

/**
 * Inside component.html:
 *   <app-modal #modal [modalConfig]="modalConfig">
 *     <!-- body of the modal -->
 *   </app-modal>
 *
 * Inside component.ts:
 *   @ViewChild('modal') private modalComponent: ModalComponent
 *   async openModal() {
 *     return await this.modalComponent.open()
 *   }
 *
 */

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
@Injectable()
export class ModalComponent implements OnInit {
  @Input() public modalConfig: ModalConfig;
  @ViewChild("modal") private modalContent: TemplateRef<ModalComponent>;
  private modalRef: NgbModalRef;

  constructor(
    private translate: TranslateService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {}

  open(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      var options = { size: this.modalConfig.windowClass || "lg" };
      if (this.modalConfig.windowClass) {
        options["windowClass"] = this.modalConfig.windowClass;
      }
      if (this.modalConfig.disableClose) {
        options["backdrop"] = 'static';
        options["keyboard"] = false;
      }
      this.modalRef = this.modalService.open(this.modalContent, options);
      this.modalRef.result.then(resolve, resolve);
    });
  }

  async close(): Promise<void> {
    if (
      this.modalConfig.shouldClose === undefined ||
      (await this.modalConfig.shouldClose())
    ) {
      const result =
        this.modalConfig.onClose === undefined ||
        (await this.modalConfig.onClose());
      this.modalRef.close(result);
    }
  }

  async dismiss(): Promise<void> {
    if (
      this.modalConfig.shouldDismiss === undefined ||
      (await this.modalConfig.shouldDismiss())
    ) {
      const result =
        this.modalConfig.onDismiss === undefined ||
        (await this.modalConfig.onDismiss());
      this.modalRef.dismiss(result);
    }
  }

  async customAction1(): Promise<void> {
    if (
      this.modalConfig.shouldCustomAction1 === undefined ||
      (await this.modalConfig.shouldCustomAction1())
    ) {
      const result =
        this.modalConfig.onCustomAction1 === undefined ||
        (await this.modalConfig.onCustomAction1());
    }
  }

  async customAction2(): Promise<void> {
    if (
      this.modalConfig.shouldCustomAction2 === undefined ||
      (await this.modalConfig.shouldCustomAction2())
    ) {
      const result =
        this.modalConfig.onCustomAction2 === undefined ||
        (await this.modalConfig.onCustomAction2());
    }
  }

  async customAction3(): Promise<void> {
    if (
      this.modalConfig.shouldCustomAction3 === undefined ||
      (await this.modalConfig.shouldCustomAction3())
    ) {
      const result =
        this.modalConfig.onCustomAction3 === undefined ||
        (await this.modalConfig.onCustomAction3());
    }
  }
}
