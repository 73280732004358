/* eslint-disable @typescript-eslint/member-ordering */
import { HttpParams } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  ControlContainer,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { AuthService } from "app/shared/api/auth.service";
import { ClosingErrorService } from "app/shared/api/closingError.service";
import { ClosingFieldService } from "app/shared/api/closingField.service";
import { ClosingRefundService } from "app/shared/api/closingRefund.service";
import { UserService } from "app/shared/api/user.service";
import { ClosingError } from "app/shared/models/closingError";
import { Operator } from "app/shared/models/operator";
import { CommonUtilsService } from "app/shared/services/common-utils.service";
import { ClosingService } from "app/shared/api/closing.service";
import { ClosingReturnService } from "app/shared/api/closingReturn.service";
import { ClosingReturn } from "app/shared/models/closingReturn";
import { ClosingRefund } from "app/shared/models/closingRefund";
import swal from "sweetalert2";

@Component({
  selector: "app-card-closing",
  templateUrl: "./card-closing.component.html",
  styleUrls: [
    "./card-closing.component.scss",
    "../../../../assets/sass/libs/datatables.scss",
  ],
})
export class CardClosingComponent implements OnInit {
  @Input() closing: any;
  @Input() form: UntypedFormGroup;
  @Input() formSubmitted: boolean;
  @Input() formArrayName: string;

  @Input() enableAddError: boolean;

  @Output() requestUpdateClosingModel = new EventEmitter<any>();
  @Output() saveForm = new EventEmitter<any>();
  @Output() hideSpinner = new EventEmitter<any>();

  closingFields: any;

  operators: Operator[] = [];

  @ViewChild("modalAddClosingError") modalAddClosingError: TemplateRef<any>;
  closingErrorPhotoSrc: any;
  closingErrorInvalidPhoto = true;
  closingErrorFormSubmitted = false;
  closingErrorFormSubmitButtonDisabled = false;
  closingErrorForm: UntypedFormGroup = new UntypedFormGroup({
    photo: new UntypedFormControl(""),
    value: new UntypedFormControl(null, [Validators.required]),
    notes: new UntypedFormControl(null, [Validators.required]),
    closing_field: new UntypedFormControl(null, [Validators.required]),
  });

  @ViewChild("modalErrors") modalErrors: TemplateRef<any>;
  @ViewChild("closingErrorTable") closingErrorTable: DatatableComponent;
  closingErrorPage = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: "",
    orderDir: "",
    closing: null,
    closing_field: null,
  };
  closingErrorColumns = [
    { name: "ID", prop: "id" },
    { name: "Employee", prop: "operator" },
    { name: "Value", prop: "value" },
    { name: "Notes", prop: "notes" },
    { name: "ClosingField", prop: "closing_field" },
  ];
  closingErrorRows: ClosingError[];
  closingErrorEditing = {};
  closingErrorEditingRow = {};

  @ViewChild("modalRefunds") modalRefunds: TemplateRef<any>;
  @ViewChild("closingRefundTable") closingRefundTable: DatatableComponent;
  closingRefundPage = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: "",
    orderDir: "",
    closing: null,
    closing_field: null,
  };
  closingRefundColumns = [
    { name: "ID", prop: "id" },
    { name: "Employee", prop: "operator" },
    { name: "Value", prop: "value" },
    { name: "Notes", prop: "notes" },
  ];
  closingRefundRows: ClosingRefund[];
  closingRefundEditing = {};
  closingRefundEditingRow = {};

  @ViewChild("modalReturns") modalReturns: TemplateRef<any>;
  @ViewChild("closingReturnTable") closingReturnTable: DatatableComponent;
  closingReturnPage = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: "",
    orderDir: "",
    closing: null,
    closing_field: null,
  };
  closingReturnColumns = [
    { name: "ID", prop: "id" },
    { name: "Employee", prop: "operator" },
    { name: "Value", prop: "value" },
    { name: "Notes", prop: "notes" },
  ];
  closingReturnRows: ClosingReturn[];
  closingReturnEditing = {};
  closingReturnEditingRow = {};

  constructor(
    private translateService: TranslateService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    public controlContainer: ControlContainer,
    private commonUtils: CommonUtilsService,
    private authService: AuthService,
    private userService: UserService,
    private closingService: ClosingService,
    private closingFieldService: ClosingFieldService,
    private closingErrorService: ClosingErrorService,
    private closingRefundService: ClosingRefundService,
    private closingReturnService: ClosingReturnService
  ) {}

  ngOnInit(): void {
    this.getClosingFields();
    this.getOperators();
  }

  fg(i) {
    return (<UntypedFormGroup>(
      (<UntypedFormGroup>this.form.get(this.formArrayName)).controls[i]
    )).controls;
  }

  getOperators() {
    let params = new HttpParams().set("no_page", ``);
    this.userService.find({ params }).subscribe(
      (data) => {
        //console.log(data);
        this.operators = data;
      },
      (error) => {
        console.log(error);
        this.operators.push(this.authService.getUser());
      }
    );
  }

  getClosingFields() {
    let params = new HttpParams()
      .set("no_page", ``)
      .set("ordering", `order`)
      .set("used", "true")
      .set("deleted", "false");
    this.closingFieldService.find({ params }).subscribe(
      (data) => {
        //console.log(data);
        this.closingFields = data;

        const closingFieldsFb = this.closingFields.map((cf: any) => {
          return this.formBuilder.group({
            id: [{ value: cf.id, disabled: true }],
            photo: cf["no_file"] ? [""] : ["", [Validators.required]],
            total: [null, [Validators.required]],
          });
        });
        this.form.setControl(
          this.formArrayName,
          this.formBuilder.array(closingFieldsFb)
        );

        this.closingErrorPage.closing = this.closing?.id;
        this.closingRefundPage.closing = this.closing?.id;
        this.hideSpinner.emit("");
      },
      (error) => {
        console.log(error);
        this.hideSpinner.emit("");
      }
    );
  }

  /**
   * Methods for cards
   */

  onClearPhoto(event: any, index: number, element: any) {
    if (this.fg(index).photo.value) {
      const photoName = this.fg(index).photo.value.split("/").pop();
      this.closingService.deletePhotoUploaded({ name: photoName }).subscribe(
        (data) => {
          //console.log(data);
          this.fg(index).photo.setValue(null);
          this.saveForm.emit("");
        },
        (error) => {
          console.log(error);
          this.fg(index).photo.setValue(null);
          this.saveForm.emit("");
        }
      );
    }
  }

  onAddAttachment(event: any, index: number, element: any) {
    const file = (event.target as HTMLInputElement).files[0];
    let formData = new FormData();
    formData.append("photo", file);
    this.closingService.photoUpload(formData).subscribe(
      (data) => {
        console.log(data);
        this.fg(index).photo.setValue(data["photo"]);
        this.saveForm.emit("");
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onInputKeyUp(event, index, element) {
    // console.log(event);
    // console.log(element);
    // console.log(event.target.value);
    // console.log(parseInt("" + event.target.value * 100) / 100);

    let dec = "1";

    for (let i = 0; i < this.commonUtils.countDecimals(event.target.value); i++)
      dec += "0";

    // console.log(dec);

    let prefix = "";

    if (element.negative && event.target.value > 0) prefix = "-";

    // console.log(prefix + event.target.value * parseInt(dec));

    this.fg(index).total.setValue(
      (parseFloat(prefix + event.target.value * parseInt(dec)) / 100).toFixed(2)
    );
  }

  OnInputChange(event, index, element) {
    //emit to save to the local storage
    this.saveForm.emit("");
  }

  /**
   * Methods for modal add closing error
   */

  get cef() {
    return this.closingErrorForm.controls;
  }

  openModalAddClosingError() {
    const modalRef = this.modalService.open(this.modalAddClosingError, {
      backdrop: "static",
      keyboard: false,
    });
  }

  closeModalAddClosingError() {
    this.closingErrorFormSubmitted = false;
    this.closingErrorPhotoSrc = null;
    this.closingErrorForm.reset();
    this.modalService.dismissAll();
  }

  saveModalAddClosingError() {
    this.closingErrorFormSubmitted = true;
    if (this.closingErrorForm.invalid || this.closingErrorInvalidPhoto) {
      return;
    }
    this.closingErrorFormSubmitButtonDisabled = true;
    let formData = new FormData();
    formData.append("value", this.closingErrorForm.get("value").value);
    formData.append("notes", this.closingErrorForm.get("notes").value);
    formData.append(
      "closing_field",
      this.closingErrorForm.get("closing_field").value
    );
    formData.append("photo", this.closingErrorForm.get("photo").value);

    this.closingErrorService.create(this.closing.id, formData).subscribe(
      (data) => {
        console.log(data);
        this.requestUpdateClosingModel.emit({});
        this.closingErrorFormSubmitButtonDisabled = false;
        this.closeModalAddClosingError();
      },
      (error) => {
        console.log(error);
        this.closingErrorFormSubmitButtonDisabled = false;
      }
    );
  }

  onModalErrorClearPhoto(item: any) {
    this.closingErrorPhotoSrc = undefined;
    this.closingErrorInvalidPhoto = true;
    this.closingErrorForm.get("photo").reset();
  }

  onModalErrorAddPhoto(event: any, element: any) {
    const file = (event.target as HTMLInputElement).files[0];
    this.closingErrorForm.patchValue({
      photo: file,
    });
    this.closingErrorForm.get("photo").updateValueAndValidity();
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.closingErrorPhotoSrc = reader.result as string;
    };
    reader.readAsDataURL(file);
    this.closingErrorInvalidPhoto = false;
  }

  onModalErrorInputKeyUp(event, element) {
    // console.log(event);
    // console.log(element);
    // console.log(event.target.value);
    // console.log(parseInt("" + event.target.value * 100) / 100);

    let dec = "1";

    for (let i = 0; i < this.commonUtils.countDecimals(event.target.value); i++)
      dec += "0";

    // console.log(dec);

    let prefix = "";

    // if (element.negative && event.target.value > 0) prefix = "-";

    // console.log(prefix + event.target.value * parseInt(dec));

    this.closingErrorForm
      .get("value")
      .setValue(
        (parseFloat(prefix + event.target.value * parseInt(dec)) / 100).toFixed(
          2
        )
      );
  }

  /**
   * Methods for closing error modal
   */

  getTotalErrors(id) {
    if (this.closing && this.closing["errors"].length > 0) {
      return (
        this.closing["errors"].filter(
          (e) => e.closing_field && e.closing_field["id"] === id
        ).length || 0
      );
    }
    return 0;
  }

  openModalErrors(closingFieldId) {
    console.log("openModalErrors");
    this.closingErrorPage.closing_field = closingFieldId;
    this.closingErrorPageCallback({ offset: 0 });
    const modalRef = this.modalService.open(this.modalErrors, {
      backdrop: "static",
      keyboard: false,
      size: "lg",
    });
    //this.openModalErrors.emit();
  }

  closeModalErrors() {
    console.log("closeModalErrors");
    this.requestUpdateClosingModel.emit({});
    this.modalService.dismissAll();
  }

  /**
   * Called whenever the user changes page
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  closingErrorPageCallback(pageInfo: {
    count?: number;
    pageSize?: number;
    limit?: number;
    offset?: number;
  }) {
    this.closingErrorPage.offset = pageInfo.offset;
    this.reloadClosingErrorTable();
  }

  /**
   * You will render the table once at the beginning in ngOnInit()
   * and then every time the page OR the sort order are changed
   */
  reloadClosingErrorTable() {
    let params = new HttpParams()
      .set(
        "ordering",
        `${this.closingErrorPage.orderDir}${this.closingErrorPage.orderBy}`
      )
      .set(
        "offset",
        `${this.closingErrorPage.offset * this.closingErrorPage.limit}`
      )
      .set("limit", `${this.closingErrorPage.limit}`)
      .set("closing", `${this.closing.id}`)
      .set("closing_field", `${this.closingErrorPage.closing_field}`);
    //console.log(params);
    this.closingErrorService.find(this.closing.id, { params }).subscribe(
      (data) => {
        console.log(data);
        this.closingErrorPage.count = data["count"];
        this.closingErrorRows = data["results"];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * onClosingErrorEditRow
   *
   * @param event
   * @param row
   * @param rowIndex
   */
  onClosingErrorEditRow(event, row, rowIndex) {
    //console.log(row);
    this.closingErrorEditing[rowIndex + "-" + row.id] = true;
    //this.closingErrorEditingRow["operator"] = row.operator;
    this.closingErrorEditingRow["value"] = row.value;
    this.closingErrorEditingRow["notes"] = row.notes;
    this.closingErrorEditingRow["closing_field"] = row.closing_field;
  }

  /**
   * onClosingErrorRemoveRow
   *
   * @param event
   * @param row
   * @param rowIndex
   */
  onClosingErrorRemoveRow(id) {
    //console.log(id);
    this.closingErrorService.remove(this.closing.id, id).subscribe(
      (data) => {
        console.log(data);
        this.requestUpdateClosingModel.emit({});
        this.reloadClosingErrorTable();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * onClosingErrorInlineEditingUpdate
   *
   * @param event
   * @param row
   * @param rowIndex
   */
  onClosingErrorInlineEditingUpdate(event, row, rowIndex) {
    this.closingErrorEditing[rowIndex + "-" + row.id] = false;
    let formData = Object.assign({}, this.closingErrorEditingRow);
    formData["closing_field"] = this.closingErrorEditingRow[
      "closing_field"
    ].hasOwnProperty("id")
      ? this.closingErrorEditingRow["closing_field"]["id"]
      : this.closingErrorEditingRow["closing_field"];
    //console.log(formData);
    this.closingErrorService
      .update(this.closing.id, this.closingErrorRows[rowIndex]["id"], formData)
      .subscribe(
        (data) => {
          console.log(data);
          this.requestUpdateClosingModel.emit({});
          this.reloadClosingErrorTable();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /**
   * onClosingErrorInlineBlurEvent
   *
   * @param event
   * @param row
   * @param rowIndex
   */
  onClosingErrorInlineBlurEvent(event, cell, rowIndex) {
    this.closingErrorEditingRow[cell] = event.target.value;
  }

  /**
   * onClosingErrorInlineEditingCancel
   *
   * @param event
   * @param row
   * @param rowIndex
   */
  onClosingErrorInlineEditingCancel(event, row, rowIndex) {
    this.closingErrorEditing[rowIndex + "-" + row.id] = false;
  }

  /**
   * Methods for closing refunds modal
   */

  getTotalRefunds() {
    if (this.closing && this.closing["refunds"].length > 0) {
      return this.closing["refunds"].length || 0;
    }
    return 0;
  }

  openModalRefunds() {
    console.log("openModalRefunds");
    this.closingRefundPageCallback({ offset: 0 });
    const modalRef = this.modalService.open(this.modalRefunds, {
      backdrop: "static",
      keyboard: false,
      size: "lg",
    });
    //this.openModalRefunds.emit();
  }

  closeModalRefunds() {
    console.log("closeModalRefunds");
    this.requestUpdateClosingModel.emit({});
    this.modalService.dismissAll();
  }

  /**
   * Called whenever the user changes page
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  closingRefundPageCallback(pageInfo: {
    count?: number;
    pageSize?: number;
    limit?: number;
    offset?: number;
  }) {
    this.closingRefundPage.offset = pageInfo.offset;
    this.reloadClosingRefundTable();
  }

  /**
   * You will render the table once at the beginning in ngOnInit()
   * and then every time the page OR the sort order are changed
   */
  reloadClosingRefundTable() {
    let params = new HttpParams()
      .set(
        "ordering",
        `${this.closingRefundPage.orderDir}${this.closingRefundPage.orderBy}`
      )
      .set(
        "offset",
        `${this.closingRefundPage.offset * this.closingRefundPage.limit}`
      )
      .set("limit", `${this.closingRefundPage.limit}`);
    //console.log(params);
    this.closingRefundService.find(this.closing.id, { params }).subscribe(
      (data) => {
        //console.log(data);
        this.closingRefundPage.count = data["count"];
        this.closingRefundRows = data["results"];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * onClosingRefundSendReminder
   *
   * @param row
   * @param event
   */
   onClosingRefundSendReminder(event, row) {
    console.log(row);
    this.closingRefundService.sendReminder(row.closing.id, row.id).subscribe(
      (data) => {
        console.log(data);
        swal.fire({
          icon: "success",
          title: this.translateService.instant("Sent"),
          text: this.translateService.instant("Reminder sent"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      },
      (error) => {
        console.log(error);
        swal.fire({
          title: this.translateService.instant("Error"),
          text: this.translateService.instant(
            "It's not possible to send reminder"
          ),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    );
  }

  /**
   * onClosingRefundEditRow
   *
   * @param event
   * @param row
   * @param rowIndex
   */
  onClosingRefundEditRow(event, row, rowIndex) {
    console.log(row);
    this.closingRefundEditing[rowIndex + "-" + row.id] = true;
    //this.closingRefundEditingRow["operator"] = row.operator;
    this.closingRefundEditingRow["value"] = row.value;
    this.closingRefundEditingRow["notes"] = row.notes;
  }

  /**
   * onClosingRefundRemoveRow
   *
   * @param event
   * @param row
   * @param rowIndex
   */
  onClosingRefundRemoveRow(id) {
    //console.log(id);
    this.closingRefundService.remove(this.closing.id, id).subscribe(
      (data) => {
        console.log(data);
        this.requestUpdateClosingModel.emit({});
        this.reloadClosingErrorTable();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * onClosingRefundInlineEditingUpdate
   *
   * @param event
   * @param row
   * @param rowIndex
   */
  onClosingRefundInlineEditingUpdate(event, row, rowIndex) {
    console.log(this.closingRefundEditingRow);
    this.closingRefundEditing[rowIndex + "-" + row.id] = false;
    this.closingRefundService
      .update(
        this.closing.id,
        this.closingRefundRows[rowIndex]["id"],
        this.closingRefundEditingRow
      )
      .subscribe(
        (data) => {
          console.log(data);
          this.requestUpdateClosingModel.emit({});
          this.reloadClosingRefundTable();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /**
   * onClosingRefundInlineBlurEvent
   *
   * @param event
   * @param row
   * @param rowIndex
   */
  onClosingRefundInlineBlurEvent(event, cell, rowIndex) {
    this.closingRefundEditingRow[cell] = event.target.value;
  }

  /**
   * onClosingRefundInlineEditingCancel
   *
   * @param event
   * @param row
   * @param rowIndex
   */
  onClosingRefundInlineEditingCancel(event, row, rowIndex) {
    this.closingRefundEditing[rowIndex + "-" + row.id] = false;
  }

  /**
   * Methods for closing returns modal
   */

  getTotalReturns() {
    if (this.closing && this.closing["returns"].length > 0) {
      return this.closing["returns"].length || 0;
    }
    return 0;
  }

  openModalReturns() {
    console.log("openModalReturns");
    this.closingReturnPageCallback({ offset: 0 });
    const modalRef = this.modalService.open(this.modalReturns, {
      backdrop: "static",
      keyboard: false,
      size: "lg",
    });
    //this.openModalReturns.emit();
  }

  closeModalReturns() {
    console.log("closeModalReturns");
    this.requestUpdateClosingModel.emit({});
    this.modalService.dismissAll();
  }

  /**
   * Called whenever the user changes page
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  closingReturnPageCallback(pageInfo: {
    count?: number;
    pageSize?: number;
    limit?: number;
    offset?: number;
  }) {
    this.closingReturnPage.offset = pageInfo.offset;
    this.reloadClosingReturnTable();
  }

  /**
   * You will render the table once at the beginning in ngOnInit()
   * and then every time the page OR the sort order are changed
   */
  reloadClosingReturnTable() {
    let params = new HttpParams()
      .set(
        "ordering",
        `${this.closingReturnPage.orderDir}${this.closingReturnPage.orderBy}`
      )
      .set(
        "offset",
        `${this.closingReturnPage.offset * this.closingReturnPage.limit}`
      )
      .set("limit", `${this.closingReturnPage.limit}`);
    //console.log(params);
    this.closingReturnService.find(this.closing.id, { params }).subscribe(
      (data) => {
        //console.log(data);
        this.closingReturnPage.count = data["count"];
        this.closingReturnRows = data["results"];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * onClosingReturnSendReminder
   *
   * @param row
   * @param event
   */
  onClosingReturnSendReminder(event, row) {
    console.log(row);
    this.closingReturnService.sendReminder(row.closing.id, row.id).subscribe(
      (data) => {
        console.log(data);
        swal.fire({
          icon: "success",
          title: this.translateService.instant("Sent"),
          text: this.translateService.instant("Reminder sent"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      },
      (error) => {
        console.log(error);
        swal.fire({
          title: this.translateService.instant("Error"),
          text: this.translateService.instant(
            "It's not possible to send reminder"
          ),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    );
  }

  /**
   * onClosingReturnEditRow
   *
   * @param event
   * @param row
   * @param rowIndex
   */
  onClosingReturnEditRow(event, row, rowIndex) {
    console.log(row);
    this.closingReturnEditing[rowIndex + "-" + row.id] = true;
    //this.closingReturnEditingRow["operator"] = row.operator;
    this.closingReturnEditingRow["value"] = row.value;
    this.closingReturnEditingRow["notes"] = row.notes;
  }

  /**
   * onClosingReturnRemoveRow
   *
   * @param event
   * @param row
   * @param rowIndex
   */
  onClosingReturnRemoveRow(id) {
    //console.log(id);
    this.closingReturnService.remove(this.closing.id, id).subscribe(
      (data) => {
        console.log(data);
        this.requestUpdateClosingModel.emit({});
        this.reloadClosingErrorTable();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * onClosingReturnInlineEditingUpdate
   *
   * @param event
   * @param row
   * @param rowIndex
   */
  onClosingReturnInlineEditingUpdate(event, row, rowIndex) {
    this.closingReturnEditing[rowIndex + "-" + row.id] = false;
    this.closingReturnService
      .update(
        this.closing.id,
        this.closingReturnRows[rowIndex]["id"],
        this.closingReturnEditingRow
      )
      .subscribe(
        (data) => {
          console.log(data);
          this.requestUpdateClosingModel.emit({});
          this.reloadClosingReturnTable();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /**
   * onClosingReturnInlineBlurEvent
   *
   * @param event
   * @param row
   * @param rowIndex
   */
  onClosingReturnInlineBlurEvent(event, cell, rowIndex) {
    this.closingReturnEditingRow[cell] = event.target.value;
  }

  /**
   * onClosingReturnInlineEditingCancel
   *
   * @param event
   * @param row
   * @param rowIndex
   */
  onClosingReturnInlineEditingCancel(event, row, rowIndex) {
    this.closingReturnEditing[rowIndex + "-" + row.id] = false;
  }
}
