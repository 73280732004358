import { Component } from "@angular/core";
import { FieldWrapper } from "@ngx-formly/core";

@Component({
  selector: "formly-wrapper-modal",
  template: `
    <div class="modal-header">
      <h5 class="modal-title">{{ to.label }}</h5>
      <button
        type="button"
        class="close"
        (click)="onClose($event)"
        aria-label="Close"
      >
        <span class="close-icon">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div [innerHTML]="to.description"></div>
      <ng-container #fieldComponent></ng-container>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onClose($event)">
        {{ to.dismissButtonLabel }}
      </button>
      <button type="button" class="btn btn-primary" (click)="onSave($event)">
        {{ to.closeButtonLabel }}
      </button>
    </div>
  `,
})
export class ModalWrapperComponent extends FieldWrapper {
  onClose($event) {
    if (this.to.onClose) {
      this.to.onClose($event);
    }
  }

  onSave($event) {
    if (this.to.onClose) {
      this.to.onSave($event);
    }
  }
}
