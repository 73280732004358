import { HttpParams } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { CompanyService } from "app/shared/api/company.service";
import { Company } from "app/shared/models/company";
import { CommonUtilsService } from "app/shared/services/common-utils.service";
import { LocalStorageService } from "app/shared/services/local-storage.service";

@Component({
  selector: "app-dashboard-filters",
  templateUrl: "./dashboard-filters.component.html",
  styleUrls: [
    "./dashboard-filters.component.scss",
    "../../../../assets/sass/libs/datepicker.scss",
    "../../../../assets/sass/libs/select.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardFiltersComponent implements OnInit {
  @Input() showFilters;
  @Input() multiCompany = false;

  @Output() formSubmit = new EventEmitter<any>();

  companies: Company[];

  hoveredDate: NgbDate | null = null;

  periods = [
    { id: 0, label: "---" },
    { id: 1, label: "Today" },
    { id: 2, label: "Yesterday" },
    { id: 3, label: "This Month" },
    { id: 4, label: "Last Month" },
    { id: 5, label: "Last Two Months" },
    { id: 6, label: "Last Three Months" },
    { id: 7, label: "Last Six Months" },
    { id: 8, label: "This Year" },
    { id: 9, label: "Last Year" },
    { id: 10, label: "Last Two Years" },
    { id: 11, label: "Last Three Years" },
    { id: 12, label: "Last Five Years" },
  ];

  filterForm: UntypedFormGroup;
  formSubmitDisabled: boolean = false;

  constructor(
    private translate: TranslateService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private formBuilder: UntypedFormBuilder,
    private commonUtils: CommonUtilsService,
    private localStorageService: LocalStorageService,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      period: [null, []],
      company: [null, [Validators.required]],
      fromDate: [null, [Validators.required]],
      toDate: [null, [Validators.required]],
    });
    this.getCompanies();
    //this.checkLocalStorage();
    this.formSubmitDisabled = this.filterForm.invalid;
    // this.formSubmitDisabled =
    //   !this.f.fromDate.value || !this.f.toDate.value || !this.f.company.value;
    if (!this.formSubmitDisabled) {
      this.onFilterFormSubmit();
    }
  }

  get f() {
    return this.filterForm.controls;
  }

  checkLocalStorage() {
    const fields =
      this.localStorageService.getItem("dashboard-filters") || undefined;
    if (fields) {
      let arr = [];
      let company;
      const f = JSON.parse(fields);
      if (f.company) {
        if (this.multiCompany) {
          if (!(this.f.company.value instanceof Array)) {
            // console.log("-if_not_array: company_not_array:", "f.company", f.company, "multiCompany", this.multiCompany, "company is array?", (f.company instanceof Array), "company is number?", (f.company instanceof Number));
            company = f.company;
          } else {
            // console.log("if_not_array: -company_array: multiCompany", this.multiCompany, "company is array?", (f.company instanceof Array), "company is number?", (f.company instanceof Number));
            f.company.map((c) => arr.push(c));
            company = arr;
          }
        } else {
          if (!(f.company instanceof Array)) {
            // console.log("-if_array: -company_not_array: multiCompany", this.multiCompany, "company is array?", (f.company instanceof Array), "company is number?", (f.company instanceof Number));
            company = f.company;
          } else {
            // console.log("-if_array: -company_array: multiCompany", this.multiCompany, "company is array?", (f.company instanceof Array), "company is number?", (f.company instanceof Number));
            company = f.company[0];
          }
        }
      }
      // console.log("extracted checkLocalStorage", f);
      this.filterForm.patchValue({
        period: f.period,
        fromDate: this.commonUtils.ngbDatePickerFromDateFormat(f.fromDate),
        toDate: this.commonUtils.ngbDatePickerFromDateFormat(f.toDate),
        company: company,
      });
    } else {
      this.filterForm.patchValue({
        period: 0,
        fromDate: this.calendar.getPrev(
          this.calendar.getToday(),
          "m",
          1
        ) as NgbDate,
        toDate: this.calendar.getToday() as NgbDate,
        company: null,
      });
    }
  }

  saveChangeToLocalStorage() {
    this.localStorageService.setItem(
      "dashboard-filters",
      JSON.stringify({
        period: this.f.period.value,
        company: this.f.company.value,
        fromDate: this.commonUtils.ngbDatePickerDateToString(
          this.f.fromDate.value
        ),
        toDate: this.commonUtils.ngbDatePickerDateToString(this.f.toDate.value),
      })
    );
    this.formSubmitDisabled = this.filterForm.invalid;
  }

  onDateSelection(date: NgbDate) {
    // console.log("onDateSelection");
    if (!this.f.fromDate.value && !this.f.toDate.value) {
      this.filterForm.get("fromDate").setValue(date);
    } else if (
      this.f.fromDate.value &&
      !this.f.toDate.value &&
      date &&
      date.after(this.f.fromDate.value)
    ) {
      this.filterForm.get("toDate").setValue(date);
    } else {
      this.filterForm.get("toDate").setValue(null);
      this.filterForm.get("fromDate").setValue(date);
    }
  }

  onChangeCompanySelect(event): void {
    //this.saveChangeToLocalStorage();
    this.formSubmitDisabled = this.filterForm.invalid;
  }

  onChangePeriodSelect(event): void {
    //console.log(event);
    var date = new Date();
    var period, fromYear, fromMonth, fromDay, toYear, toMonth, toDay;

    switch (event) {
      case "0":
        console.log("---");
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        break;
      case "1":
        console.log("Today");
        var firstDay = date;
        var lastDay = date;
        break;
      case "2":
        console.log("Yesterday");
        var firstDay = date;
        var lastDay = date;
        lastDay.setDate(date.getDate() - 1);
        break;
      case "3":
        console.log("This Month");
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        break;
      case "4":
        console.log("Last Month");
        var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        break;
      case "5":
        console.log("Last Two Months");
        var firstDay = new Date(date.getFullYear(), date.getMonth() - 2, 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        break;
      case "6":
        console.log("Last Three Months");
        var firstDay = new Date(date.getFullYear(), date.getMonth() - 3, 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        break;
      case "7":
        console.log("Last Six Months");
        var firstDay = new Date(date.getFullYear(), date.getMonth() - 6, 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        break;
      case "8":
        console.log("This Year");
        var firstDay = new Date(date.getFullYear(), 0, 1);
        var lastDay = new Date(date.getFullYear(), 12, 0);
        break;
      case "9":
        console.log("Last Year");
        var firstDay = new Date(date.getFullYear() - 1, 0, 1);
        var lastDay = new Date(date.getFullYear() - 1, 12, 0);
        break;
      case "10":
        console.log("Last Two Years");
        var firstDay = new Date(date.getFullYear() - 2, 0, 1);
        var lastDay = new Date(date.getFullYear() - 1, 11, 0);
        break;
      case "11":
        console.log("Last Three Years");
        var firstDay = new Date(date.getFullYear() - 3, 0, 1);
        var lastDay = new Date(date.getFullYear() - 1, 11, 0);
        break;
      case "12":
        console.log("Last Five Years");
        var firstDay = new Date(date.getFullYear() - 4, 0, 1);
        var lastDay = new Date(date.getFullYear() - 1, 11, 0);
        break;
      default:
        console.log("No period");
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        break;
    }
    fromYear = firstDay.getFullYear();
    fromMonth = firstDay.getMonth() + 1;
    fromDay = firstDay.getDate();
    toYear = lastDay.getFullYear();
    toMonth = lastDay.getMonth() + 1;
    toDay = lastDay.getDate();
    this.filterForm.patchValue({
      period: event,
      fromDate: new NgbDate(fromYear, fromMonth, fromDay),
      toDate: new NgbDate(toYear, toMonth, toDay),
    });
    //this.saveChangeToLocalStorage();
    this.formSubmitDisabled = this.filterForm.invalid;
  }

  isHovered(date: NgbDate) {
    return (
      this.f.fromDate.value &&
      !this.f.toDate.value &&
      this.hoveredDate &&
      date.after(this.f.fromDate.value) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return (
      this.f.toDate.value &&
      date.after(this.f.fromDate.value) &&
      date.before(this.f.toDate.value)
    );
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.f.fromDate.value) ||
      (this.f.toDate.value && date.equals(this.f.toDate.value)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  /**
   * Called whenever the user reset filter form
   */
  onFilterFormReset(): void {
    this.f.company.setValue(null);
    this.f.fromDate.setValue(
      this.calendar.getPrev(this.calendar.getToday(), "m", 1)
    );
    this.f.toDate.setValue(this.calendar.getToday());
    this.formSubmitDisabled = this.filterForm.invalid;
    //this.reloadData();
  }

  /**
   * Called whenever the user reset filter form
   */
  onFilterFormSubmit(): void {
    const data = {
      company: this.f.company.value,
      fromDate: this.commonUtils.ngbDatePickerDateToString(
        this.f.fromDate.value
      ),
      toDate: this.commonUtils.ngbDatePickerDateToString(this.f.toDate.value),
    };
    console.log("onFilterFormSubmit", data);
    this.formSubmit.emit(data);
  }

  /**
   * Called to get all companies
   */
  getCompanies() {
    let params = new HttpParams().set("no_page", ``);
    this.companyService.find({ params }).subscribe(
      (data) => {
        console.log(data);
        this.companies = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
