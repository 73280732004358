<ng-template #modalAddClosingError let-close="closeModalAddClosingError()">
  <div class="modal-header">
    <h5 class="modal-title">{{ "Add Human Error" | translate }}</h5>
    <button type="button" class="close" (click)="closeModalAddClosingError()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="needs-validation" [formGroup]="closingErrorForm" (ngSubmit)="saveModalAddClosingError()">
      <div class="{{ closingErrorFormSubmitted ? 'mb-4' : '' }} form-group">
        <label for="photo">{{ "Photo" | translate }}</label>
        <div class="input-group">
          <div class="media">
            <img [src]="closingErrorPhotoSrc || 'assets/img/no-image.png'" class="rounded mr-3" height="64"
              width="64" />
            <div class="media-body">
              <div class="col-12 d-flex flex-sm-row flex-column justify-content-start px-0 mb-sm-2">
                <label *ngIf="cef.photo" class="btn btn-sm bg-light-primary mb-sm-0" for="photo">{{ "Change Photo"
                  | translate }}</label>
                <label *ngIf="!cef.photo" class="btn btn-sm bg-light-primary mb-sm-0" for="photo">{{ "Select Photo"
                  | translate }}</label>
                <button class="btn btn-sm bg-light-secondary ml-sm-2" [disabled]="!cef.photo"
                  (click)="onModalErrorClearPhoto()">
                  {{ "Reset" | translate }}
                </button>
              </div>
              <p class="text-muted mb-0 mt-1 mt-sm-0">
                <small>{{
                  "Image Upload Size Description" | translate
                  }}</small>
              </p>
            </div>
            <input type="file" id="photo" class="form-control" style="display: none"
              (change)="onModalErrorAddPhoto($event)"
              [ngClass]="{ 'is-invalid': closingErrorFormSubmitted && closingErrorInvalidPhoto, 'is-valid': closingErrorFormSubmitted && closingErrorInvalidPhoto }"
              accept="image/png, image/jpeg" />
            <div *ngIf="closingErrorFormSubmitted && !closingErrorInvalidPhoto" class="valid-tooltip">
              <i class="ft-check-circle align-middle"></i>
              {{ "Looks Good" | translate }}
            </div>
            <div *ngIf="closingErrorFormSubmitted && closingErrorInvalidPhoto" class="invalid-tooltip">
              <i class="ft-alert-circle align-middle"></i>
              {{ "Required" | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="{{ closingErrorFormSubmitted ? 'mb-4' : '' }} form-group">
        <label for="notes">{{ "Notes" | translate }}</label>
        <div class="input-group">
          <textarea type="text" class="form-control" formControlName="notes" placeholder="{{ 'Notes' | translate }}"
            [ngClass]="{
              'is-invalid': closingErrorFormSubmitted && cef.notes.invalid,
              'is-valid': closingErrorFormSubmitted && !cef.notes.invalid
            }">
          </textarea>
          <div *ngIf="closingErrorFormSubmitted && !cef.notes.invalid" class="valid-tooltip">
            <i class="ft-check-circle align-middle"></i>
            {{ "Looks Good" | translate }}
          </div>
          <div *ngIf="closingErrorFormSubmitted && cef.notes.errors?.required" class="invalid-tooltip">
            <i class="ft-alert-circle align-middle"></i>
            {{ "Required" | translate }}
          </div>
        </div>
      </div>

      <div class="{{ closingErrorFormSubmitted ? 'mb-4' : '' }} form-group">
        <label for="value">{{ "Value" | translate }}</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">€</span>
          </div>
          <input type="number" step="0.01" class="form-control" formControlName="value" value="0.00" [ngClass]="{
              'is-invalid': closingErrorFormSubmitted && cef.value.invalid,
              'is-valid': closingErrorFormSubmitted && !cef.value.invalid
            }" (keyup)="onModalErrorInputKeyUp($event, item)" />
          <div *ngIf="closingErrorFormSubmitted && !cef.value.invalid" class="valid-tooltip">
            <i class="ft-check-circle align-middle"></i>
            {{ "Looks Good" | translate }}
          </div>
          <div *ngIf="closingErrorFormSubmitted && cef.value.errors?.required" class="invalid-tooltip">
            <i class="ft-alert-circle align-middle"></i>
            {{ "Required" | translate }}
          </div>
        </div>
      </div>

      <div class="{{ closingErrorFormSubmitted ? 'mb-4' : '' }} form-group">
        <label for="closing_field">{{ "Closing Field" | translate }}</label>
        <div class="input-group">
          <select class="form-control" formControlName="closing_field" [ngClass]="{
              'is-invalid':
                closingErrorFormSubmitted && cef.closing_field.invalid,
              'is-valid':
                closingErrorFormSubmitted && !cef.closing_field.invalid
            }">
            <option *ngFor="let cf of closingFields" value="{{ cf.id }}">
              {{ cf.name }}
            </option>
          </select>
          <div *ngIf="closingErrorFormSubmitted && !cef.closing_field.invalid" class="valid-tooltip">
            <i class="ft-check-circle align-middle"></i>
            {{ "Looks Good" | translate }}
          </div>
          <div *ngIf="
              closingErrorFormSubmitted && cef.closing_field.errors?.required
            " class="invalid-tooltip">
            <i class="ft-alert-circle align-middle"></i>
            {{ "Required" | translate }}
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" [disabled]="closingErrorFormSubmitButtonDisabled" (click)="saveModalAddClosingError()">
      {{ "Save" | translate }}
    </button>
    <button type="button" class="btn btn-secondary" (click)="closeModalAddClosingError()">
      {{ "Close" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #modalErrors let-close="closeModalErrors()">
  <div class="modal-header">
    <h5 class="modal-title">{{ "Show Human Errors" | translate }}</h5>
    <button type="button" class="close" (click)="closeModalErrors()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngx-datatable #closingErrorTable class="bootstrap core-bootstrap" [rows]="closingErrorRows"
      [columns]="closingErrorColumns" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
      [rowHeight]="'auto'" [externalPaging]="true" [externalSorting]="true" [count]="closingErrorPage.count"
      [offset]="closingErrorPage.offset" [limit]="closingErrorPage.limit" [sortType]="'single'"
      (page)="closingErrorPageCallback($event)"
      [messages]="{emptyMessage: 'No data to display' | translate, totalMessage: 'Total' | translate, selectedMessage: false}"
      [scrollbarH]="true">
      <ngx-datatable-column [name]="'ID'| translate" prop="id" [width]="50"></ngx-datatable-column>
      <ngx-datatable-column [name]="'Employee'| translate" prop="operator" [width]="150">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          {{ value.full_name }}
          <!--<div *ngIf="!closingErrorEditing[rowIndex + '-' + row.id]">
            {{ value.full_name }}
          </div>
          <select class="form-control form-control-sm"
            (blur)="onClosingErrorInlineBlurEvent($event, 'operator', rowIndex)"
            *ngIf="(closingErrorEditing[rowIndex + '-' + row.id] && (authService.getUser().isSuperuser || authService.getUser().isStaff || authService.getUser().isSupervisor))">
            <option *ngFor="let e of operators" [value]="e.id" [selected]="e.id == value.id">{{ e.full_name }}</option>
          </select>-->
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="'Notes'| translate" prop="notes" [width]="150">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <div *ngIf="!closingErrorEditing[rowIndex + '-' + row.id]">
            {{ value }}
          </div>
          <input autofocus class="form-control form-control-sm"
            (blur)="onClosingErrorInlineBlurEvent($event, 'notes', rowIndex)"
            *ngIf="closingErrorEditing[rowIndex + '-' + row.id]" type="text" [value]="value" />
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="'Value'| translate" prop="value" [width]="150">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <div *ngIf="!closingErrorEditing[rowIndex + '-' + row.id]">
            {{ value | currency: 'EUR' }}
          </div>
          <input autofocus class="form-control form-control-sm"
            (blur)="onClosingErrorInlineBlurEvent($event, 'value', rowIndex)"
            *ngIf="closingErrorEditing[rowIndex + '-' + row.id]" type="text" [value]="value" />
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="'ClosingField'| translate" prop="closing_field" [width]="150">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <div *ngIf="!closingErrorEditing[rowIndex + '-' + row.id]">
            {{ value.name }}
          </div>
          <select class="form-control form-control-sm"
            (blur)="onClosingErrorInlineBlurEvent($event, 'closing_field', rowIndex)"
            *ngIf="closingErrorEditing[rowIndex + '-' + row.id]">
            <option *ngFor="let e of closingFields" [value]="e.id" [selected]="e.id == value.id">{{ e.name }}</option>
          </select>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="'Actions'| translate" prop="id" [width]="150" [sortable]="false"
        style="overflow:hidden">
        <ng-template let-rowIndex="rowIndex" let-row="row" let-id="value" ngx-datatable-cell-template>
          <i class="ft-save text-primary cursor-pointer mr-2" container="body" placement="top"
            ngbTooltip="{{ 'Save'| translate }}" (click)="onClosingErrorInlineEditingUpdate($event, row, rowIndex)"
            (blur)="onClosingErrorInlineBlurEvent($event, '', rowIndex)"
            *ngIf="closingErrorEditing[rowIndex + '-' + row.id]"></i>
          <i class="ft-x-circle text-primary cursor-pointer mr-2" container="body" placement="top"
            ngbTooltip="{{ 'Cancel'| translate }}" (click)="onClosingErrorInlineEditingCancel($event, row, rowIndex)"
            (blur)="onClosingErrorInlineBlurEvent($event, '', rowIndex)"
            *ngIf="closingErrorEditing[rowIndex + '-' + row.id]"></i>
          <i class="ft-edit text-primary cursor-pointer mr-2" container="body" placement="top"
            ngbTooltip="{{ 'Edit'| translate }}" (click)="onClosingErrorEditRow($event, row, rowIndex)"
            *ngIf="(!closingErrorEditing[rowIndex + '-' + row.id] && (authService.getUser().isSuperuser || authService.getUser().isStaff || authService.getUser().isSupervisor)) || (!closingErrorEditing[rowIndex + '-' + row.id] && row.operator.id == authService.getUser().id)"></i>
          <i class="ft-trash-2 text-primary cursor-pointer" container="body" placement="top"
            ngbTooltip="{{ 'Delete'| translate }}" (click)="onClosingErrorRemoveRow(id)"
            *ngIf="(!closingErrorEditing[rowIndex + '-' + row.id] && (authService.getUser().isSuperuser || authService.getUser().isStaff || authService.getUser().isSupervisor)) || (!closingErrorEditing[rowIndex + '-' + row.id] && row.operator.id == authService.getUser().id)"></i>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModalErrors()">
      {{ "Close" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #modalRefunds let-close="closeModalRefunds">
  <div class="modal-header">
    <h5 class="modal-title">{{ "Show Refunds" | translate }}</h5>
    <button type="button" class="close" (click)="closeModalRefunds()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngx-datatable #closingRefundTable class="bootstrap core-bootstrap" [rows]="closingRefundRows"
      [columns]="closingRefundColumns" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
      [rowHeight]="'auto'" [externalPaging]="true" [externalSorting]="true" [count]="closingRefundPage.count"
      [offset]="closingRefundPage.offset" [limit]="closingRefundPage.limit" [sortType]="'single'"
      (page)="closingRefundPageCallback($event)"
      [messages]="{emptyMessage: 'No data to display' | translate, totalMessage: 'Total' | translate, selectedMessage: false}"
      [scrollbarH]="true">
      <ngx-datatable-column [name]="'ID'| translate" prop="id" [width]="50"></ngx-datatable-column>
      <ngx-datatable-column [name]="'Employee'| translate" prop="operator" [width]="150">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <div *ngIf="!closingRefundEditing[rowIndex + '-' + row.id]">
            {{ value.full_name }}
          </div>
          <select class="form-control form-control-sm"
            (blur)="onClosingRefundInlineBlurEvent($event, 'operator', rowIndex)"
            *ngIf="closingRefundEditing[rowIndex + '-' + row.id]">
            <option *ngFor="let e of operators" [value]="e.id" [selected]="e.id == value.id">{{ e.full_name }}</option>
          </select>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="'Notes'| translate" prop="notes" [width]="150">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <div *ngIf="!closingRefundEditing[rowIndex + '-' + row.id]">
            {{ value }}
          </div>
          <input autofocus class="form-control form-control-sm"
            (blur)="onClosingRefundInlineBlurEvent($event, 'notes', rowIndex)"
            *ngIf="closingRefundEditing[rowIndex + '-' + row.id]" type="text" [value]="value" />
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="'Value'| translate" prop="value" [width]="150">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <div *ngIf="!closingRefundEditing[rowIndex + '-' + row.id]">
            {{ value | currency: 'EUR' }}
          </div>
          <input autofocus class="form-control form-control-sm"
            (blur)="onClosingRefundInlineBlurEvent($event, 'value', rowIndex)"
            *ngIf="closingRefundEditing[rowIndex + '-' + row.id]" type="text" [value]="value" />
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="'Actions'| translate" prop="id" [width]="150" [sortable]="false"
        style="overflow:hidden">
        <ng-template let-rowIndex="rowIndex" let-row="row" let-id="value" ngx-datatable-cell-template>
          <i class="ft-save text-primary cursor-pointer mr-2" container="body" placement="top"
            ngbTooltip="{{ 'Save'| translate }}" (click)="onClosingRefundInlineEditingUpdate($event, row, rowIndex)"
            (blur)="onClosingRefundInlineBlurEvent($event, '', rowIndex)"
            *ngIf="closingRefundEditing[rowIndex + '-' + row.id]"></i>
          <i class="ft-x-circle text-primary cursor-pointer mr-2" container="body" placement="top"
            ngbTooltip="{{ 'Cancel'| translate }}" (click)="onClosingRefundInlineEditingCancel($event, row, rowIndex)"
            (blur)="onClosingRefundInlineBlurEvent($event, '', rowIndex)"
            *ngIf="closingRefundEditing[rowIndex + '-' + row.id]"></i>
          <i class="ft-send text-primary cursor-pointer mr-2" container="body" placement="top"
            ngbTooltip="{{ 'Send Reminder'| translate }}" (click)="onClosingRefundSendReminder($event, row)"
            *ngIf="!closingRefundEditing[rowIndex + '-' + row.id]"></i>
          <i class="ft-edit text-primary cursor-pointer mr-2" container="body" placement="top"
            ngbTooltip="{{ 'Edit'| translate }}" (click)="onClosingRefundEditRow($event, row, rowIndex)"
            *ngIf="!closingRefundEditing[rowIndex + '-' + row.id]"></i>
          <i class="ft-trash-2 text-primary cursor-pointer" container="body" placement="top"
            ngbTooltip="{{ 'Delete'| translate }}" (click)="onClosingRefundRemoveRow(id)"
            *ngIf="!closingRefundEditing[rowIndex + '-' + row.id]"></i>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModalRefunds()">
      {{ "Close" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #modalReturns let-close="closeModalReturns">
  <div class="modal-header">
    <h5 class="modal-title">{{ "Show Returns" | translate }}</h5>
    <button type="button" class="close" (click)="closeModalReturns()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngx-datatable #closingReturnTable class="bootstrap core-bootstrap" [rows]="closingReturnRows"
      [columns]="closingReturnColumns" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
      [rowHeight]="'auto'" [externalPaging]="true" [externalSorting]="true" [count]="closingReturnPage.count"
      [offset]="closingReturnPage.offset" [limit]="closingReturnPage.limit" [sortType]="'single'"
      (page)="closingReturnPageCallback($event)"
      [messages]="{emptyMessage: 'No data to display' | translate, totalMessage: 'Total' | translate, selectedMessage: false}"
      [scrollbarH]="true">
      <ngx-datatable-column [name]="'ID'| translate" prop="id" [width]="50"></ngx-datatable-column>
      <ngx-datatable-column [name]="'Employee'| translate" prop="operator" [width]="150">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <div *ngIf="!closingReturnEditing[rowIndex + '-' + row.id]">
            {{ value.full_name }}
          </div>
          <select class="form-control form-control-sm"
            (blur)="onClosingReturnInlineBlurEvent($event, 'operator', rowIndex)"
            *ngIf="closingReturnEditing[rowIndex + '-' + row.id]">
            <option *ngFor="let e of operators" [value]="e.id" [selected]="e.id == value.id">{{ e.full_name }}</option>
          </select>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="'Notes'| translate" prop="notes" [width]="150">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <div *ngIf="!closingReturnEditing[rowIndex + '-' + row.id]">
            {{ value }}
          </div>
          <input autofocus class="form-control form-control-sm"
            (blur)="onClosingReturnInlineBlurEvent($event, 'notes', rowIndex)"
            *ngIf="closingReturnEditing[rowIndex + '-' + row.id]" type="text" [value]="value" />
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="'Value'| translate" prop="value" [width]="150">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <div *ngIf="!closingReturnEditing[rowIndex + '-' + row.id]">
            {{ value | currency: 'EUR' }}
          </div>
          <input autofocus class="form-control form-control-sm"
            (blur)="onClosingReturnInlineBlurEvent($event, 'value', rowIndex)"
            *ngIf="closingReturnEditing[rowIndex + '-' + row.id]" type="text" [value]="value" />
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="'Actions'| translate" prop="id" [width]="150" [sortable]="false"
        style="overflow:hidden">
        <ng-template let-rowIndex="rowIndex" let-row="row" let-id="value" ngx-datatable-cell-template>
          <i class="ft-save text-primary cursor-pointer mr-2" container="body" placement="top"
            ngbTooltip="{{ 'Save'| translate }}" (click)="onClosingReturnInlineEditingUpdate($event, row, rowIndex)"
            (blur)="onClosingReturnInlineBlurEvent($event, '', rowIndex)"
            *ngIf="closingReturnEditing[rowIndex + '-' + row.id]"></i>
          <i class="ft-x-circle text-primary cursor-pointer mr-2" container="body" placement="top"
            ngbTooltip="{{ 'Cancel'| translate }}" (click)="onClosingReturnInlineEditingCancel($event, row, rowIndex)"
            (blur)="onClosingReturnInlineBlurEvent($event, '', rowIndex)"
            *ngIf="closingReturnEditing[rowIndex + '-' + row.id]"></i>
          <i class="ft-send text-primary cursor-pointer mr-2" container="body" placement="top"
            ngbTooltip="{{ 'Send Reminder'| translate }}" (click)="onClosingReturnSendReminder($event, row)"
            *ngIf="!closingReturnEditing[rowIndex + '-' + row.id]"></i>
          <i class="ft-edit text-primary cursor-pointer mr-2" container="body" placement="top"
            ngbTooltip="{{ 'Edit'| translate }}" (click)="onClosingReturnEditRow($event, row, rowIndex)"
            *ngIf="!closingReturnEditing[rowIndex + '-' + row.id]"></i>
          <i class="ft-trash-2 text-primary cursor-pointer" container="body" placement="top"
            ngbTooltip="{{ 'Delete'| translate }}" (click)="onClosingReturnRemoveRow(id)"
            *ngIf="!closingReturnEditing[rowIndex + '-' + row.id]"></i>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModalReturns()">
      {{ "Close" | translate }}
    </button>
  </div>
</ng-template>




<div class="row justify-content-center mt-2 mb-2">
  <button *ngxPermissionsOnly="['Superusers']" type="button" class="btn btn-primary mt-2 mb-2 mr-2" (click)="openModalRefunds()">
    {{ "Total Refunds" | translate }} <span class="badge badge-secondary">{{ getTotalRefunds() }}</span>
  </button>
  <button *ngxPermissionsOnly="['Superusers']" type="button" class="btn btn-primary mt-2 mb-2 mr-4" (click)="openModalReturns()">
    {{ "Total Returns" | translate }} <span class="badge badge-warning">{{ getTotalReturns() }}</span>
  </button>
  <button *ngIf="enableAddError" type="button" class="btn btn-danger mt-2 mb-2" (click)="openModalAddClosingError()">
    <i class="fa-solid fa-triangle-exclamation mr-1"></i> {{ "Add Human Error" | translate }}
  </button>
</div>

<ng-container [formGroup]="form">
  <div class="row justify-content-center">
    <div class="card mx-2 mb-3" *ngFor="let closingField of closingFields; let i = index"
      [formArrayName]="formArrayName">
      <div class="card-header">
        <div class="card-badge">
          <span class="badge badge-danger mr-1" title="{{ 'Total Human Errors' | translate }}" style="cursor: pointer"
            (click)="openModalErrors(closingField.id)">{{ getTotalErrors(closingField.id) }}</span>
        </div>
        <h4 class="card-title">#{{ closingField.id }}</h4>
        <p class="card-subtitle">{{ closingField.name }}</p>
      </div>
      <div class="card-content" [formGroupName]="i">
        <div class="card-img">
          <img class="img-fluid" [src]="fg(i).photo.value || 'assets/img/no-image.png'"
            [ngStyle]="{ 'height': (formSubmitted) ? '330px' : '310px', 'max-width': (formSubmitted) ? '330px' : '310px'}"
            alt="{{ closingField.name }}" />
          <a *ngIf="!fg(i).photo.value && !closingField.no_file" class="btn btn-floating halfway-fab btn-large bg-danger"
            (click)="fileInput.click()" style="
                border-radius: 50%;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 0px;
                padding-right: 0px;
                padding-top: 0px;
                padding-bottom: 0px;
                position: absolute;
                bottom: -10px;
                right: 12px;
              ">
            <i class="fa fa-camera fa-2x" aria-hidden="true"></i>
            <input #fileInput id="{{ closingField.slug }}-file" type="file" accept="image/png, image/jpeg"
              capture="environment" (change)="onAddAttachment($event, i, item)" [disabled]="closingField.no_file"
              style="display: none" />
          </a>
          <a *ngIf="fg(i).photo.value && !closingField.no_file" class="btn btn-floating halfway-fab btn-large bg-danger"
            (click)="onClearPhoto($event, i, item)" style="
            border-radius: 50%;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 0px;
            padding-right: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
            position: absolute;
            bottom: -10px;
            right: 12px;
          ">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </a>
        </div>
        <div class="card-body">
          <div class="{{ formSubmitted ? 'mb-4' : '' }} form-group">
            <div class="input-group mt-2">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">€</span>
              </div>
              <input type="number" class="form-control" placeholder="0,00" step="0.01" formControlName="total"
                [ngClass]="{ 'is-invalid': formSubmitted && fg(i).total.invalid, 'is-valid': formSubmitted && !fg(i).total.invalid }"
                (keyup)="onInputKeyUp($event, i, closingField)" (change)="OnInputChange($event, i, closingField)" />
              <div *ngIf="formSubmitted && !fg(i).total.invalid" class="valid-tooltip"><i
                  class="ft-check-circle align-middle"></i> {{ 'Looks Good'| translate }}</div>
              <div *ngIf="formSubmitted && fg(i).total.invalid && fg(i).total.errors?.required" class="invalid-tooltip">
                <i class="ft-alert-circle align-middle"></i> {{ 'Required'| translate }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-muted pt-0" style="display: flex; justify-content: center; align-items: center">
        <span class="tags float-right">
          <span *ngIf="closingField.negative" class="badge bg-primary mb-1 mr-1">{{ "Negative" | translate }}</span>
          <span *ngIf="closingField.no_file" class="badge bg-success">{{
            "No File Needed" | translate
            }}</span>
          <span *ngIf="!closingField.no_file" class="badge bg-danger">{{
            "File Required" | translate
            }}</span>
        </span>
      </div>
    </div>
  </div>
</ng-container>