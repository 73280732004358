import { Injectable } from "@angular/core";
import { AbstractService } from "./abstract.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Employee } from "../models/employee";
import { SessionStorageService } from "../services/session-storage.service";

@Injectable({
  providedIn: "root",
})
export class EmployeeService extends AbstractService {
  private url = "/employees";

  constructor(httpClient: HttpClient, encryptStorage: SessionStorageService) {
    super(httpClient, encryptStorage);
  }

  find(params: any): Observable<Employee[]> {
    return this.get(`${this.url}/`, params);
  }

  retrieve(id: any): Observable<Employee> {
    return this.get(`${this.url}/${id}/`);
  }

  create(data: any): Observable<any> {
    return this.post(`${this.url}/`, data);
  }

  update(id: any, data: any): Observable<any> {
    return this.put(`${this.url}/${id}/`, data);
  }

  update2(id: any, data: any): Observable<any> {
    return this.patch(`${this.url}/${id}/`, data);
  }

  remove(id: any): Observable<any> {
    return this.delete(`${this.url}/${id}/`);
  }

  activate(id: any): Observable<any> {
    return this.get(`${this.url}/${id}/activate/`);
  }

  deactivate(id: any): Observable<any> {
    return this.get(`${this.url}/${id}/deactivate/`);
  }

  my(): Observable<any> {
    return this.get(`${this.url}/my/`);
  }

  uploadSignedContract(id: any, data: any): Observable<any> {
    return this.put(`${this.url}/${id}/sign_contract/`, data);
  }

  getActiveEmployees(): Observable<Employee[]> {
    const params = new HttpParams()
      .set("no_page", ``)
      .set("profile__operator__is_employee", `true`)
      .set("profile__operator__is_active", `true`)
      .set("ordering", `profile__operator__full_name`);
    return this.get(`${this.url}/`, { params });
  }

  /*
  findByTitle(title: any): Observable<Employee[]> {
    return this.get(`${this.url}/?title=${title}`);
  }
*/
}
