import { Component } from "@angular/core";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";

@Component({
  selector: "formly-field-slider",
  template: `
    <div class="form-group">
      <nouislider
        [min]="0"
        [max]="20"
        [step]="1"
        [formControl]="formControl"
        [tooltips]="[true]"
      ></nouislider>
    </div>
  `,
})
export class FormlyFieldSlider extends FieldType<FieldTypeConfig> {}
