import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isLoggedIn = this.authService.isLoggedIn();

    if (!isLoggedIn) {
      this.router.navigate(['/pages/login'],{queryParams:{'redirectTo': state.url}});
      //this.router.navigate(["/pages/login"]);
    } else {
      let user = this.authService.getUser();
      //console.log(user);
      this.authService.refreshRolePermissions();
      // TODO: implement the email verification check
      //if (!user.isEmailVerified) {
      //  this.router.navigate(['/pages/email-verification']);
      //}
      // check if user is active
      if (!user.isActive) {
        this.router.navigate(["/pages/login"]);
      }
      // TODO: implement route permissions (with operatorGroups and operatorPermissions)

      return true;
    }
  }
}
