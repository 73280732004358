import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from "chart.js";
import { BaseChartDirective } from "ng2-charts";

@Component({
  selector: "app-dashboards-radar-chart",
  templateUrl: "./radar-chart.component.html",
  styleUrls: ["./radar-chart.component.scss"],
})
export class RadarChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  @Input() public labels: any[] = [];
  @Input() public dataset: any[] = [];

  public chartOptions: ChartConfiguration["options"] = {
    responsive: true,
  };

  public chartData: ChartData<"radar"> = {
    labels: this.labels,
    datasets: this.dataset,
  };
  public chartType: ChartType = "radar";

  constructor() {}

  ngOnInit(): void {
    this.chartData = {
      labels: this.labels,
      datasets: this.dataset,
    };
    //this.chart.update();
  }

  // events
  public chartClicked({
    event,
    active,
  }: {
    event: ChartEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event: ChartEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }
}
